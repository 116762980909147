export const EMDESENVOLVIMENTO: boolean = false

export const VERSAO_SISTEMA = 'V2.03'

export const PORT_ENDPOINT: number = 4008

export const URL_ENDPOINT: string = EMDESENVOLVIMENTO ? 'localhost' : 'app.fleekonline.com.br'

export const URL_CLIENT: string = 'http'.concat( EMDESENVOLVIMENTO ? '://' : 's://' ).concat( URL_ENDPOINT ).concat( EMDESENVOLVIMENTO ? ':8080/' : '/' )

export const ENDPOINT_GRAPHQL: string = 'http'.concat( EMDESENVOLVIMENTO ? '://' : 's://' ).concat( URL_ENDPOINT ).concat( ':' ).concat( PORT_ENDPOINT.toString() ).concat( '/graphql' )

export const ENDPOINT: string = 'http'.concat( EMDESENVOLVIMENTO ? '://' : 's://' ).concat( URL_ENDPOINT ).concat( ':' ).concat( PORT_ENDPOINT.toString() ).concat( '/' )

export const TAMANHO_MAXIMO_SELFIE = 20000 * 1024

export const ID_GRUPO_ALUNO = 2

/*

export const PORT_WEB_SERVICE: number = 8085


export const ID_USUARIO_SITE: number = 8

export const ID_ORIGEM_SITE: number = 6

export const ID_ORIGEM_INDICACAO: number = 10

*/
