




































import s from './InputCompletarCls'
export default s
