













































































import Vue from 'vue'
import Component from 'vue-class-component'
import { LoginStoreModule, StateLoginInterface } from '@/store/modules/LoginStore'
import { MENU, MENUFILHOSINTERFACE, MENUINTERFACE } from '@/config/Menu';
import { NotificacaoStoreModule } from '@/store/modules/NotificacaoStore';
import ClsAcesso from '@/utils/ClsAcesso';

@Component
export default class MenuInicial extends Vue {

  constructor() {
    super()
    if ( !LoginStoreModule.logado ) {
      this.$router.push( 'Login' );
    }
  }

  private get MENU (): Array<MENUINTERFACE> {
    return MENU
  }

  private get LoginStoreModule (): StateLoginInterface {
    return LoginStoreModule
  }

  private getFilhosMenu ( oque: Array<MENUINTERFACE> ): Array<MENUFILHOSINTERFACE> {
    let retorno: Array<MENUFILHOSINTERFACE> = []
    let clsAcesso: ClsAcesso = new ClsAcesso()
    oque.forEach( ( valor: MENUINTERFACE ) => {
      if ( valor.filhos && clsAcesso.chkAcesso(valor.modulo, valor.permissao) ) {
        retorno = [...retorno, ...this.getFilhosMenu( valor.filhos )]
      } else if ( clsAcesso.chkAcesso(valor.modulo, valor.permissao) ) {
        retorno.push( {
          icone: valor.icone,
          texto: valor.texto,
          link: valor.link ? valor.link : '',
          corBotao: valor.corBotao ? valor.corBotao : '',
          corIcone: valor.corIcone ? valor.corIcone : ''
        } );
      }
    } )

    return retorno
  }

  private btClickMenu ( destino: string ): void {

    NotificacaoStoreModule.refreshNotificacao( this )

    if ( destino && this.$router.currentRoute.path != destino ) {
      this.$router.push( destino )
    }

  }

}
