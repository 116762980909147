import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/src/locale/pt';

import AccountShool from '@/customIcons/AccountSchool.vue'

Vue.use( Vuetify );

export default new Vuetify( {
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#007BFF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        laranjaFleek: '#EE7D00',
        azulFleek: '#007bff'
      },
    },
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
  icons: {
    values: {
      AccountShool: { // name of our custom icon
        component: AccountShool, // our custom component
      },
    },
  },
} );
