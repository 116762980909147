import { ENDPOINT } from "@/config/backend/emDesenvolvimento"
import { AulaMidiaInterface } from "@/interfaces/backend/AulaInterfaces"
import { AtualizarEstatisticaInterface } from "@/interfaces/backend/EstatisticaInterfaces"
import { RespostaPadraoInterface } from "@/interfaces/backend/PadraoInterfaces"
import { LoginStoreModule } from "@/store/modules/LoginStore"
import ClsApolloClient from "@/utils/ClsApolloClient"
import ClsEstilo from "@/utils/ClsEstilo"
import axios from "axios"
import Vue from "vue"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { clsUtils } from "zlib-utils"

@Component

export default class PlayCls extends Vue {

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  @Prop()
  private rsAtualizarEstatistica: AtualizarEstatisticaInterface

  @Prop( { default: () => [] } )
  private downloads: Array<AulaMidiaInterface>

  @Prop( { default: false } )
  private concluido: boolean

  private tmpClsEstilo: ClsEstilo = new ClsEstilo()

  private get clsEstilo (): ClsEstilo {
    return this.tmpClsEstilo
  }

  private atualizarDownload ( idAulaMidia: number ): Promise<RespostaPadraoInterface> {

    if ( !this.concluido ) {

      const mutation: string = `
      updateDownloadRealizado(
        rsAtualizarEstatistica: ${clsUtils.ConverterEmGql( this.rsAtualizarEstatistica )}, 
        idAulaMidia: ${idAulaMidia}
        ) {
          ok
          mensagem
        }
        `

      return this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'updateDownloadRealizado' )

    } else {

      return Promise.resolve( { ok: true, mensagem: 'Aula Concluida.' } )

    }

  }

  private realizarDownload ( idAulaMidia: number, filename: string ): Promise<boolean> {

    const urlVideo: string = ENDPOINT.concat( 'playAulaMidia/'.concat( idAulaMidia.toString() ).concat( '/' ).concat( LoginStoreModule.token ) )

    return axios.get( urlVideo, {

      responseType: 'json',

    } ).then( rs => {

      return axios.get( rs.data.link, {
        responseType: 'arraybuffer'
      } ).then( rs => {
        const url = window.URL.createObjectURL( new Blob( [rs.data] ) )
        const link = document.createElement( 'a' )
        link.href = url
        link.setAttribute( 'download', filename ) //or any other extension
        document.body.appendChild( link )
        link.click()
        return true
      } ).catch( () => {
        return false
      } )


    } ).catch( ( e ) => {
      console.log( e )
      return false
    } )


    /*

    return axios.get( this.clsEstilo.getSrcMidia( idAulaMidia ), {
      responseType: 'arraybuffer'
    } ).then( rs => {
      const url = window.URL.createObjectURL( new Blob( [rs.data], { type: "image/png" } ) )
      const link = document.createElement( 'a' )
      link.href = url
      link.setAttribute( 'download', filename ) //or any other extension
      document.body.appendChild( link )
      link.click()
      return true
    } ).catch( () => {
      return false
    } )

    */

  }

  private btClickDownload ( idAulaMidia: number, filename: string ) {

    this.realizarDownload( idAulaMidia, filename ).then( rs => {
      if ( rs ) {
        this.atualizarDownload( idAulaMidia )
      }
    } )

  }
}