import { Component, Prop, Vue } from 'vue-property-decorator'

import { StatusFormType } from '@/utils/dataTypes'

import AutoCompleteCad from '@/components/autoCompleteCad.vue'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { CursoAssuntoTrilhaAulaInterface } from '@/interfaces/backend/CursoInterfaces'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'

/*
import { clsUtils } from 'zlib-utils'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { CursoAssuntoTrilhaAulaInterface, CursoAssuntoTrilhaInterface } from '@/interfaces/backend/CursoInterfaces'
*/

@Component( { components: { AutoCompleteCad } } )
export default class CursoAssuntoTrilhaCls extends Vue {

  private idAula: number = 0

  @Prop( {
    default: 0
  } )
  private idCursoAssuntoTrilha: number

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private status: StatusFormType = StatusFormType.inicial

  private clsApollo: ClsApolloClient = new ClsApolloClient()
  private registros: Array<CursoAssuntoTrilhaAulaInterface> = []

  private cabecalhos = [
    { text: 'Título', value: 'titulo' },
    { text: 'Descrição', value: 'descricao' },
    { text: 'Ordem', value: 'ordem' },
    { text: 'Ações', value: 'actions', sortable: false }
  ]

  private mounted () {
    this.getDetalhe()
  }

  private incluirAula () {

    if ( this.idAula && !this.registros.some( v => {
      return v.idAula == this.idAula
    } ) ) {

      const query: string = `
        incluirCursoAssuntoTrilhasAulas(idCursoAssuntoTrilha: ${this.idCursoAssuntoTrilha}, idAula: ${this.idAula}, ordem: ${this.registros.length + 1}) {
          ok
          mensagem
        }
      `

      this.clsApollo.mutation<Array<RespostaPadraoInterface>>( this, query, 'incluirCursoAssuntoTrilhasAulas', 'Incluindo Aula...' ).then( rs => {
        this.getDetalhe()
      } )
    }

  }

  private getDetalhe () {

    this.registros = []

    if ( this.idCursoAssuntoTrilha > 0 ) {


      const query: string = `
      getCursoAssuntoTrilhasAulas(idCursoAssuntoTrilha: ${this.idCursoAssuntoTrilha}) {
        idCursoAssuntoTrilha
        idAula
        titulo
        descricao
        ordem
      }
      `

      this.clsApollo.query<Array<CursoAssuntoTrilhaAulaInterface>>( this, query, 'getCursoAssuntoTrilhasAulas', 'Pequisando Aulas...' ).then( rs => {
        this.registros = rs
      } )

    }

  }

  // Drag And Drop

  private startDrag ( evt: DragEvent, idCursoAssuntoTrilha: number, idAula: number, ordem: number ) {

    if ( evt.dataTransfer ) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData( 'idCursoAssuntoTrilha', idCursoAssuntoTrilha.toString() )
      evt.dataTransfer.setData( 'idAula', idAula.toString() )
      evt.dataTransfer.setData( 'ordemDrag', ordem.toString() )
    }

  }

  private onDrop ( evt: DragEvent, idCursoAssuntoTrilhaDrop: number, idAulaDrop: number, ordemDrop: number ) {

    if ( evt.dataTransfer ) {

      const idCursoAssuntoTrilhaDrag = parseInt( evt.dataTransfer.getData( 'idCursoAssuntoTrilha' ) )
      const idAulaDrag = parseInt( evt.dataTransfer.getData( 'idAula' ) )
      const ordemDrag = parseInt( evt.dataTransfer.getData( 'ordemDrag' ) )

      this.dragAndDrop( idCursoAssuntoTrilhaDrag, idAulaDrag, ordemDrag, idCursoAssuntoTrilhaDrop, idAulaDrop, ordemDrop )

    }
  }

  private dragAndDrop ( idCursoAssuntoTrilhaDrag: number, idAulaDrag: number, ordemDrag: number, idCursoAssuntoTrilhaDrop: number, idAulaDrop: number, ordemDrop: number ): void {

    const mutation: string = `
        trocarOrdemCursoAssuntoTrilhaAula(
          idCursoAssuntoTrilhaDrag: ${idCursoAssuntoTrilhaDrag},
          idAulaDrag: ${idAulaDrag},
          ordemDrag: ${ordemDrag},
          idCursoAssuntoTrilhaDrop: ${idCursoAssuntoTrilhaDrop},
          idAulaDrop: ${idAulaDrop},
          ordemDrop: ${ordemDrop}
        ) {
          ok
          mensagem
        }      
      `

    this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'trocarOrdemCursoAssuntoTrilhaAula', 'Alterando Ordem das Aulas...' ).then( rs => {
      if ( rs.ok ) {

        this.getDetalhe()

      } else {

        MensagemStoreModule.exibirMensagem( {
          mensagem: rs.mensagem,
          tipo: TipoMensagemType.erro,
          titulo: 'Erro'

        } )

      }

    } )
  }

  private deleteItem ( item: CursoAssuntoTrilhaAulaInterface ) {

    const mutation: string = `
        delCursoAssuntoTrilhaAula(idCursoAssuntoTrilha: ${item.idCursoAssuntoTrilha}, idAula: ${item.idAula} ) {
          ok
          mensagem
        }
      `

    this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'delCursoAssuntoTrilhaAula', 'Excluindo Aula da Trilha do Curso....' ).then( rs => {
      if ( rs.ok ) {
        this.status = StatusFormType.inicial
        this.getDetalhe()
      } else {

        MensagemStoreModule.exibirMensagem( {
          mensagem: rs.mensagem,
          tipo: TipoMensagemType.erro,
          titulo: 'Erro!!!'
        } )

      }
    } )

  }

}
