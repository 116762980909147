import Vue from 'vue'
import Vuex from 'vuex'
import { StateDataInterface } from './modules/DataStore';
import { StateLoginInterface } from './modules/LoginStore';
import { StateNotificacaoInterface } from './modules/NotificacaoStore';
import { StateStatusInterface } from './modules/StatusStore';

Vue.use( Vuex )

export interface IRootState {
  login: StateLoginInterface;
  data: StateDataInterface;
  notificacao: StateNotificacaoInterface;
  status: StateStatusInterface
}

const store = new Vuex.Store<IRootState>( {
} );

export default store;