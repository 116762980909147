import { rsPlayAulaExercicioInterface, rsPlayAulaInterface } from "@/interfaces/backend/PlayInterfaces"
import { ExercicioPropriedadesInterface } from "@/interfaces/ExercicioInterfaces"
import { elementoHTMLType } from "@/types/backend/ExerciciosDataType"
import ClsEstilo from "@/utils/ClsEstilo"
import Vue from "vue"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"

import InputTeste from '@/components/teste/InputTeste.vue'
import InputCompletar from '@/components/completar/InputCompletar.vue'
import PlayAvaliacao from './PlayAvaliacao.vue'
import { AtualizarEstatisticaInterface } from "@/interfaces/backend/EstatisticaInterfaces"
import { clsUtils } from "zlib-utils"
import { RespostaPadraoInterface } from "@/interfaces/backend/PadraoInterfaces"
import ClsApolloClient from "@/utils/ClsApolloClient"

@Component( {
  components: {
    InputCompletar,
    InputTeste,
    PlayAvaliacao
  }
} )
export default class PlayCls extends Vue {

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private emEdicao: boolean = false
  private isAvaliacao: boolean = false

  @Prop( { default: () => [] } )
  private exercicios: Array<rsPlayAulaExercicioInterface>

  @Prop( { default: () => 1 } )
  private keyRefresh: number

  @Prop( { default: () => { return {} } } )
  private rsAtualizarEstatistica: AtualizarEstatisticaInterface

  @Prop( { default: () => { return { concluido: false } } } )
  private rsAula: rsPlayAulaInterface

  private rsExercicios: Array<rsPlayAulaExercicioInterface> = []

  private exercicioAtual: number = 0
  private propriedades: Array<ExercicioPropriedadesInterface> = []

  @Watch( 'exercicios' )
  private onChangeExercicios () {
    this.rsExercicios = JSON.parse( JSON.stringify( this.exercicios ) )
    this.alterarExercicioAtual( 0 )

    if ( this.rsExercicios.length === 0 ) {
      this.isAvaliacao = true
    }

  }

  private alterarExercicioAtual ( indice: number ) {
    this.exercicioAtual = indice
    if ( this.rsExercicios.length > 0 && this.rsExercicios[this.exercicioAtual].propriedades ) {
      this.propriedades = JSON.parse( <string>this.rsExercicios[this.exercicioAtual].propriedades )
    }
  }

  private elementoHTMLType: typeof elementoHTMLType = elementoHTMLType

  private tmpClsEstilo: ClsEstilo = new ClsEstilo()

  private get clsEstilo (): ClsEstilo {
    return this.tmpClsEstilo
  }

  private mounted () {
    this.onChangeExercicios()
  }

  private btProximo () {
    if ( this.exercicioAtual < ( this.rsExercicios.length - 1 ) ) {
      this.alterarExercicioAtual( this.exercicioAtual + 1 )
    } else {
      if ( !this.isAvaliacao && this.exercicioAtual == this.rsExercicios.length - 1 ) {
        this.isAvaliacao = true
      } else {
        this.isAvaliacao = false
        this.alterarExercicioAtual( 0 )
      }
    }
  }

  private btAnterior () {
    if ( this.exercicioAtual > 0 && !this.isAvaliacao ) {
      this.alterarExercicioAtual( this.exercicioAtual - 1 )
      this.isAvaliacao = false
    } else {
      this.isAvaliacao = false
      this.alterarExercicioAtual( this.rsExercicios.length - 1 )
    }
  }

  private get resposta () {
    if ( this.rsExercicios && this.rsExercicios[this.exercicioAtual] && this.rsExercicios[this.exercicioAtual].resposta ) {
      return JSON.parse( this.rsExercicios[this.exercicioAtual].resposta )
    } else {
      return []
    }
  }

  // Atualização de Resultados de Exercícios

  private isUpdating: boolean = false

  private onChangeTeste ( resposta: Array<number | string>, ptsAproveitamento: number, respostaCorreta: boolean ) {

    if ( !this.rsAula.concluido ) {

      const jsonResposta: string = JSON.stringify( resposta ).replace( /"/g, '\\"' )

      const mutation: string = `
      updateAulaExercicio(
        rsAtualizarEstatistica: ${clsUtils.ConverterEmGql( this.rsAtualizarEstatistica )}, 
          idAulaExercicio: ${this.rsExercicios[this.exercicioAtual].idAulaExercicio}
          resposta: "${jsonResposta}"
          ptsAcertoAproveitamento: ${ptsAproveitamento}
          respostaCorreta: ${respostaCorreta}
          ) {
          ok
          mensagem
        }
        `

      this.isUpdating = true

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'updateAulaExercicio' ).then( rs => {
        if ( rs.ok ) {
          this.isUpdating = false
        }
      } )

      this.rsExercicios[this.exercicioAtual].resposta = JSON.stringify( resposta )

    }

  }

  private get tituloExercicio (): string {

    let retorno: string = ''

    if ( !this.isAvaliacao && this.rsExercicios && this.rsExercicios[this.exercicioAtual] && this.rsExercicios[this.exercicioAtual].titulo ) {
      retorno = this.rsExercicios[this.exercicioAtual].titulo
    } else if ( this.isAvaliacao && !this.rsAula.concluido ) {
      retorno = 'Avaliar e Finalizar'
    } else {
      retorno = 'Aula Finalizada'
    }

    return retorno

  }

  private isExercicioCorreto: boolean = false

  private get correcao (): string {
    let retorno: string = ''
    this.isExercicioCorreto = false

    if ( !this.isAvaliacao && this.rsExercicios && this.rsExercicios[this.exercicioAtual] ) {

      if ( this.rsExercicios[this.exercicioAtual].correcao ) {
        retorno = this.rsExercicios[this.exercicioAtual].correcao
      }

      this.isExercicioCorreto = this.rsExercicios[this.exercicioAtual].respostaCorreta
    }

    return retorno
  }

  private proximaAula () {
    this.$emit( 'proximaAula' )
  }


  // =========================================== Correcoes dos Exercicios
  /*

  private isExercicioCorreto: boolean = false

  private verificarExercicioCorreto () {

    if ( this.rsAula.concluido && !this.isAvaliacao && this.rsExercicios && this.rsExercicios[this.exercicioAtual] ) {

      const propriedades: Array<ExercicioConteudoInterface> = JSON.parse( <string>this.rsExercicios[this.exercicioAtual].propriedades )

      propriedades.forEach( ( v ) => {
        if ( v.elementoHTML == elementoHTMLType.TESTE ) {

          //           if ( this.rsExercicios[this.exercicioAtual].resposta ) {

          const rsResposta: Array<number> = JSON.parse( this.rsExercicios[this.exercicioAtual].resposta )

          this.isExercicioCorreto = JSON.stringify( v.teste.opcoesCorretas.sort() ) == JSON.stringify( rsResposta.sort() )

          //          }

        } else if ( v.elementoHTML == elementoHTMLType.COMPLETE ) {

          this.isExercicioCorreto = true
          v.complete.forEach( ( v ) => {
            this.isExercicioCorreto = this.isExercicioCorreto && this.chkCompletarCorreto( v.texto )
          } )
        }
      } )

    }

    return true

  }

  private chkCompletarCorreto ( frase: string ): boolean {

    const inicio: string = '{'
    const termino: string = '}'

    let indiceInicio: number = frase.indexOf( inicio )
    let indiceTermino: number = frase.indexOf( termino )

    const rsRespostaCorreta: Array<string> = []

    while ( indiceTermino > indiceInicio && indiceInicio >= 0 ) {

      const propriedades: ExercicioCompletarPropriedadesInterface = JSON.parse( frase.substring( indiceInicio, indiceTermino + 1 ) )

      rsRespostaCorreta.push( propriedades.resposta )

      frase = frase.substring( indiceTermino + 1 )

      indiceInicio = frase.indexOf( inicio )
      indiceTermino = frase.indexOf( termino )

    }

    const rsResposta: Array<string> = Array.from( this.rsExercicios[this.exercicioAtual].resposta )

    return JSON.stringify( rsResposta.sort() ) == JSON.stringify( rsRespostaCorreta.sort() )

  }

  // =========================================== Final Correcoes dos Exercicios
  */

}