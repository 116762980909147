import Vue from "vue"
import Component from "vue-class-component"

import { Prop } from "vue-property-decorator"
import { ClsCalculos } from "@/utils/ClsCalculos"
import ClsEstilo from "@/utils/ClsEstilo"
import { AlunoCursoTypeInterface } from "@/interfaces/backend/AlunoInterfaces"
import ClsApolloClient from "@/utils/ClsApolloClient"
import { STATUS_STORE_MUTATION } from "@/store/modules/StatusStore"
import store from "@/store"
import { ENDPOINT } from "@/config/backend/emDesenvolvimento"
import { LoginStoreModule } from "@/store/modules/LoginStore"
import axios from "axios"

@Component
export default class MeusCursosCls extends Vue {

  private linkImagemIcone: string = ""
  private keyRefreshImage: number = 0

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private tmpClsEstilo: ClsEstilo = new ClsEstilo()

  private get clsEstilo (): ClsEstilo {
    return this.tmpClsEstilo
  }

  private idAula: number = 0
  private idMatricula: number = 0
  private idCurso: number = 0

  @Prop()
  private rsCurso: AlunoCursoTypeInterface

  private mounted () {
    this.canPlay()
  }

  private get progresso (): number {
    return new ClsCalculos( this.rsCurso ).progresso
  }

  private get aproveitamento (): number {
    return new ClsCalculos( this.rsCurso ).aproveitamento
  }

  private canPlay () {

    const query: string = `
      proximaAula(idMatricula: ${this.rsCurso.idMatricula}, idCurso: ${this.rsCurso.idCurso})
    `

    this.clsApollo.query<number>( this, query, 'proximaAula' ).then( rs => {
      if ( rs ) {
        this.idAula = rs
        this.idMatricula = this.rsCurso.idMatricula
        this.idCurso = this.rsCurso.idCurso
        this.getIconeCurso()
      }
    } )

  }

  private exibir () {
    this.$emit( 'exibir', this.rsCurso.idMatricula, this.rsCurso.idCurso )
  }

  private btPlayCurso ( idMatricula: number, idCurso: number, idAula: number ) {
    store.commit( STATUS_STORE_MUTATION.updateAula, { idMatricula: idMatricula, idAula: idAula, idCurso: idCurso } )
    this.$router.push( '/play' )
  }

  private getIconeCurso (): void {

    if ( this.rsCurso.idCurso ) {

      const urlIcone: string = ENDPOINT.concat( 'downloadCurso/'.concat( this.rsCurso.idCurso.toString() ).concat( '/icone/' ).concat( LoginStoreModule.token ) )

      axios.get( urlIcone, {

        responseType: 'json',

      } ).then( rs => {

        this.linkImagemIcone = rs.data.link
        this.keyRefreshImage++

      } )

    }

  }

}
