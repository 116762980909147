import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Login from '@/views/sistema/login/Login.vue'
import Logout from '@/views/sistema/login/Logout.vue'
import MenuInicial from '@/layout/MenuInicial.vue'

import Teste from '@/testes_apagar/Teste.vue'

import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento'
import { LoginStoreModule } from '@/store/modules/LoginStore'

import AlterarSenha from '@/views/sistema/usuario/AlterarSenha.vue'
import EsqueciSenha from '@/views/sistema/login/EsqueciSenha.vue'
import ResetPassword from '@/views/sistema/login/ResetPassword.vue'
import Grupo from '@/views/sistema/grupo/Grupo.vue'
import Usuario from '@/views/sistema/usuario/Usuario.vue'

import Selfie from '@/views/sistema/usuario/Selfie.vue'

import Aula from '@/views/aula/Aula.vue'
import Curso from '@/views/curso/Curso.vue'
import Matricula from '@/views/alunos/Matricula.vue'
import MeusCursos from '@/views/alunos/MeusCursos.vue'
import Play from '@/views/alunos/Play.vue'

import Turma from '@/views/turma/Turma.vue'

Vue.use( VueRouter )

const rotasLivresLogin: Array<string> = ['Login', 'Testes', 'EsqueciSenha', 'resetPassword', '/resetPassword/:token']

const rotasAdministrativas: Array<RouteConfig> = [
  {
    path: '/Aula',
    name: 'Aula',
    component: Aula
  },
  {
    path: '/Curso',
    name: 'Curso',
    component: Curso
  }
]

const rotasTeste: Array<RouteConfig> = EMDESENVOLVIMENTO ? [
  {
    path: '/Testes',
    name: 'Testes',
    component: Teste
  }
] : []

const rotasSistema: Array<RouteConfig> = [
  {
    path: '/AlterarSenha',
    name: 'AlterarSenha',
    component: AlterarSenha,
  },
  {
    path: '/EsqueciSenha',
    name: 'EsqueciSenha',
    component: EsqueciSenha
  },
  {
    path: '/Grupo',
    name: 'Grupo',
    component: Grupo
  },
  {
    path: '/Matricula',
    name: 'Matricula',
    component: Matricula
  },
  {
    path: '/MeusCursos',
    name: 'MeusCursos',
    component: MeusCursos
  },
  {
    path: '/play',
    name: 'Play',
    component: Play,
    props: true
  },
  {
    path: '/Usuario',
    name: 'Usuario',
    component: Usuario
  },
  {
    path: '/Selfie',
    name: 'Selfie',
    component: Selfie
  },
  {
    path: '/Turma',
    name: 'Turma',
    component: Turma
  },
  {
    path: '/resetPassword/:token',
    name: 'resetPassword',
    component: ResetPassword,
    props: true
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/',
    name: 'MenuInicial',
    component: MenuInicial
  }
  /*,
{
  path: '/about',
  name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ // '../views/About.vue')
  // }
]

const router = new VueRouter( {
  routes: rotasSistema.concat( rotasTeste ).concat( rotasAdministrativas )
} )

router.beforeResolve( ( to, from, next ) => {

  // console.log( 'to:', to, 'from:', from )

  if ( rotasLivresLogin.includes( to.name ? to.name : '' ) || LoginStoreModule.logado || to.name == "Login" ) {
    // console.log( 'logado ou /Login ok....' )
    next() //proceed to the route
  } else {
    // console.log( 'não logado, login....' )
    if ( from.path != '/Login' || to.path != '/Login' ) {
      next( { replace: true, path: '/Login' } )
    } else {
      // console.log( 'ultimo next....' )
      next()
    }
  }
} )

export default router
