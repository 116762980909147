












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'

interface AutoCompleteInterface {
  codigo: number
  descricao: string
}

@Component
export default class AutoCompleteCad extends Vue {

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private keyRefresh: number = 10000

  private items: Array<AutoCompleteInterface> = []

  @Prop( { default: 'id' } )
  private campoCodigo: string

  /*
    @Prop( { default: 'descricao' } )
    private campoDescricao: string
    */

  @Prop()
  private value: string

  @Prop( { default: undefined } )
  private rules: string

  @Prop( { default: '' } )
  private titulo: string

  @Prop( { default: '' } )
  private classePadrao: string

  @Prop( { default: '' } )
  private query: string

  @Prop( { default: 'pesquisa' } )
  private termoPesquisa: string

  @Prop( { default: false } )
  private disabled: boolean

  @Prop( { default: () => [] } )
  private camposPesquisa: Array<string>

  private inputEvent ( novoValor: number ): void {
    this.$emit( 'input', novoValor )
  }

  @Watch( 'value' )
  private onChangeValue () {
    if ( this.value && !this.items.length ) {
      this.getRegistroAtual()
    }
  }

  private search = ""
  private loading = false

  private getRegistroAtual (): void {

    if ( parseInt( this.value ) > 0 ) {

      this.loading = true

      this.items = []

      MensagemStoreModule.exibirMensagem( {
        mensagem: 'Pequisando '.concat( this.titulo ).trim().concat( '....' ),
        tipo: TipoMensagemType.aguarde,
        titulo: 'Pesquisa'
      } )

      const query = `
      ${this.query}(${this.campoCodigo}: ${this.value}) {
          ${this.campoCodigo}
          ${this.camposPesquisa.toString()}
        }
    `
      this.clsApollo.query<Array<Record<string, unknown>>>( this, query, this.query ).then( rs => {
        this.updateItems( rs )
        this.loading = false
        MensagemStoreModule.ocultarMensagem()
        // this.keyRefresh++
      } )

    }

  }

  private getItems (): void {

    this.loading = true

    this.items = []

    MensagemStoreModule.exibirMensagem( {
      mensagem: 'Pequisando '.concat( this.titulo ).trim().concat( '....' ),
      tipo: TipoMensagemType.aguarde,
      titulo: 'Pesquisa'
    } )

    const query = `
      ${this.query}(${this.termoPesquisa}: "${this.search ? this.search : ''}") {
          ${this.campoCodigo}
          ${this.camposPesquisa.toString()}
        }
    `

    this.clsApollo.query<Array<Record<string, unknown>>>( this, query, this.query ).then( rs => {
      this.updateItems( rs )
      this.loading = false
      MensagemStoreModule.ocultarMensagem()
      // this.keyRefresh++
    } )
  }

  private updateItems ( rs: Array<Record<string, unknown>> ) {
    rs.forEach( ( v ) => {

      let descricao: string = ''
      this.camposPesquisa.forEach( ( campo, index ) => {

        if ( index > 0 ) {
          descricao = descricao.concat( ' | ' )
        }

        descricao = descricao.concat( v[campo] as string )

      } )

      let codigo: number = v[this.campoCodigo] as number

      this.items.push( {
        codigo: codigo, descricao: descricao
      } )

    } )

  }

}
