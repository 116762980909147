import { VuexModule, Module, getModule, Action, Mutation } from 'vuex-module-decorators'
import store from '@/store'
import { LoginStoreModule } from './LoginStore'

export interface StateDataInterface {
  updateAll (): void
  /*
    produtos: Array<ProdutoInterface>
    canais: Array<CanalInterface>
    origens: Array<OrigemInterface>
    tiposLead: Array<TipoLeadInterface>
    status: Array<StatusInterface>
    usuarios: Array<UsuarioCadastroInterface>
    updateOrigens ( payload: any ): Promise<Array<OrigemInterface>>
    updateProdutos ( payload: any ): Promise<Array<ProdutoInterface>>
    updateUsuarios ( payload: any ): Promise<Array<UsuarioCadastroInterface>>
    */
}

@Module( { dynamic: true, store, name: 'dataStore' } )
class DataStore extends VuexModule implements StateDataInterface {

  @Action( { commit: 'teste' } )
  async updateAll (): Promise<boolean> {
    if ( LoginStoreModule.logado ) {
      /*
      await store.dispatch( 'updateProdutos', payload )
      await store.dispatch( 'updateOrigens', payload )
      await store.dispatch( 'updateUsuarios', payload )
      await store.dispatch( 'refreshNotificacao', payload )
      */
      return Promise.resolve( true )
    } else {
      return Promise.resolve( false )
    }
  }

  @Mutation
  teste (): void {
    // console.log('dentro do teste mutation....')
  }

  /*
    produtos: Array<ProdutoInterface> = []
    canais: Array<CanalInterface> = CanalRoleDescricao
    origens: Array<OrigemInterface> = []
    tiposLead: Array<TipoLeadInterface> = TipoLeadRoleDescricao
    status: Array<StatusInterface> = StatusRoleDescricao
    usuarios: Array<UsuarioCadastroInterface> = []
  
    @Mutation
    updateOrigemMutation ( origens: Array<OrigemInterface> ): void {
      this.origens = origens
    }
  
    @Mutation
    updateProdutoMutation ( produtos: Array<ProdutoInterface> ): void {
      this.produtos = produtos
    }
  
    @Mutation
    updateUsuarioMutation ( usuarios: Array<UsuarioCadastroInterface> ): void {
      this.usuarios = usuarios
    }
  
    @Action( { commit: 'updateUsuarioMutation' } )
    updateUsuarios ( payload: any ): Promise<Array<UsuarioCadastroInterface>> {
  
      let clsApolloClient: ClsApolloClient = new ClsApolloClient()
  
      let query: string = `
          getUsuarios {
            idUsuario
            nome
          }
      `
  
      return clsApolloClient.query<Array<UsuarioCadastroInterface>>( payload, query, 'getUsuarios' ).then( rs => {
        return rs
      } ).catch( err => {
        console.log( 'ERRO: ', err )
        return []
      } )
  
    }
  
    @Action( { commit: 'updateProdutoMutation' } )
    updateProdutos ( payload: any ): Promise<Array<ProdutoInterface>> {
  
      let clsApolloClient: ClsApolloClient = new ClsApolloClient()
  
      let getProduto: string = `
          getProdutos {
            idProduto
            descricao
            ativo
          }
      `
  
      return clsApolloClient.query<Array<ProdutoInterface>>( payload, getProduto, 'getProdutos' ).then( rs => {
        return rs
      } ).catch( err => {
        console.log( 'ERRO: ', err )
        return []
      } )
  
    }
  
    @Action( { commit: 'updateOrigemMutation' } )
    updateOrigens ( payload: any ): Promise<Array<OrigemInterface>> {
  
      let clsApolloClient: ClsApolloClient = new ClsApolloClient()
  
      let getOrigem: string = `
          getOrigens {
            idOrigem
            descricao
            ativa
          }
      `
  
      return clsApolloClient.query<Array<OrigemInterface>>( payload, getOrigem, 'getOrigens' ).then( rs => {
        return rs
      } ).catch( err => {
        console.log( 'ERRO: ', err )
        return []
      } )
  
    }
    */

}

export const DataStoreModule: StateDataInterface = getModule( DataStore, store )