interface SistemaPermissoesInterface {
  [key: string]: {
    MODULO: string
    PERMISSOES: {
      [key: string]: string
      // EXCLUIR: string,

    }
  }
}

export const MAXIMO_ERRO_LOGIN: number = 3

export const SISTEMA_PERMISSOES: SistemaPermissoesInterface = Object.freeze( {
  ALUNOS: {
    MODULO: 'APP do Aluno',
    PERMISSOES: {
      ACESSO: 'Acesso Geral como Aluno'
    }
  },
  AULAS: {
    MODULO: 'Cadastro de Aulas',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Aulas'
    }
  },
  CONTEUDOS: {
    MODULO: 'Cadastro de Conteúdos',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Conteúdos'
    }
  },
  CURSOS: {
    MODULO: 'Cadastro de Cursos',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Cursos'
    }
  },
  MATRICULAS: {
    MODULO: 'Cadastro de Matrículas',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Matrículas'
    }
  },
  GRUPOS: {
    MODULO: 'Cadastro de Grupos de Usuários',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Grupos'
    }
  },
  TURMAS: {
    MODULO: 'Cadastro de Turmas',
    PERMISSOES: {
      ACESSO: 'Acesso a Cadastro de Turmas',
      MANUTENCAO: 'Manutenção de Turmas',
      LIBERAR_AULAS: 'Liberação de Aulas / Cronograma',
      DIAS_LETIVOS: 'Dias Letivos / Feriados',
      MATRICULAS: 'Matrículas'
    }
  },
  USUARIOS: {
    MODULO: 'Cadastro de Usuário',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Usuários',
      ALTERAR_SENHA: 'Alterar Senha',
    }
  }
} )
