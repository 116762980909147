
























































































import s from './ExercicioCls'
export default s
