import { Component, Vue } from 'vue-property-decorator'

import { clsUtils } from 'zlib-utils'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { StatusFormType } from '@/utils/dataTypes'
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento'
import AutoCompleteCad from '@/components/autoCompleteCad.vue'
import { getMatriculasInterface, MatriculaInputInterface } from '@/interfaces/backend/MatriculaInterfaces'

interface PesquisaInterface {
  idUsuario: number
  idCurso: number
  idProfessor: number
}

@Component( {
  components: {
    AutoCompleteCad
  }
} )
export default class CursoCls extends Vue {

  private pesquisa: PesquisaInterface = { idCurso: 0, idUsuario: 0, idProfessor: 0 }

  private formValid = false

  private editedItem: MatriculaInputInterface = this.resetModel()
  private tmpRegistro: MatriculaInputInterface = this.resetModel()

  private resetModel (): MatriculaInputInterface {
    return {
      idUsuario: 0,
      idCurso: 0,
      idProfessor: 0,
      degustacao: true,
      cancelada: false
    }
  }
  private status: StatusFormType = StatusFormType.inicial

  private tituloForm: string = "Cadastro de Matrículas"

  private registros: Array<getMatriculasInterface> = []

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private btCancelar (): void {
    this.status = StatusFormType.inicial
  }

  private btIncluir (): void {

    this.tituloForm = 'Incluir Matrícula'

    this.status = StatusFormType.incluindo

    if ( EMDESENVOLVIMENTO ) {
      this.editedItem = {
        idUsuario: 0,
        idCurso: 1,
        idProfessor: 1,
        degustacao: true,
        cancelada: false
      }
    } else {
      this.editedItem = this.resetModel()
    }
  }

  private btConfirmar (): void {

    if ( this.status == StatusFormType.editando || this.status == StatusFormType.incluindo ) {

      if ( ( this.$refs.form as Vue & { validate: () => boolean } ).validate() ) {

        const dados: MatriculaInputInterface = {
          idCurso: this.editedItem.idCurso,
          idUsuario: this.editedItem.idUsuario,
          idProfessor: this.editedItem.idProfessor,
          degustacao: this.editedItem.degustacao,
          cancelada: this.editedItem.cancelada
        }

        if ( this.status != StatusFormType.incluindo ) {
          dados.idMatricula = this.editedItem.idMatricula
        }

        const mutation: string = `
            updateMatricula(dados: ${clsUtils.ConverterEmGql( dados )} ) {
              ok
              mensagem
            }
          `

        this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'updateMatricula', 'Atualizando Matrícula' ).then( rs => {
          if ( rs.ok ) {
            this.status = StatusFormType.inicial
            this.getDetalhe()
          }
        } )

      }
    }
    /* else if ( this.status == StatusFormType.excluindo ) {

      const mutation: string = `
        delMatricula(idUsuario: ${this.editedItem.idUsuario} , idCurso: ${this.editedItem.idCurso} ) {
          ok
          mensagem
        }
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'delMatricula', 'Excluindo Matrícula....' ).then( rs => {
        if ( rs.ok ) {
          this.status = StatusFormType.inicial
          this.getDetalhe()
        } else {

          MensagemStoreModule.exibirMensagem( {
            mensagem: rs.mensagem,
            tipo: TipoMensagemType.erro,
            titulo: 'Erro!!!'
          } )

        }
      } )

    }
    */
  }

  private cabecalhos = [
    { text: 'Matrícula', value: 'idMatricula' },
    { text: 'Aluno', value: 'aluno' },
    { text: 'Título', value: 'titulo' },
    { text: 'Professor', value: 'professor' },
    { text: '% Conc.', value: 'ptsConclusao' },
    { text: 'Apr.', value: 'ptsAcertoAproveitamento' },
    { text: 'Sat.', value: 'pesquisaSatisfacao' },
    { text: 'Acesso', value: 'ultimoAcesso' },
    { text: 'Conc.', value: 'concluido' },
    { text: 'Gratis', value: 'degustacao' },
    { text: 'Cancel', value: 'cancelada' },
    { text: 'Ações', value: 'actions', sortable: false }
  ]

  private viewItem ( item: getMatriculasInterface ) {
    this.tituloForm = "Visualizar Matrícula"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.exibindo
  }

  private editItem ( item: getMatriculasInterface ) {
    this.tituloForm = "Alterar Matrícula"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.editando
  }

  /*
  private deleteItem ( item: getMatriculasInterface ) {
    this.tituloForm = "Excluir Matrícula"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.excluindo
  }
  */

  private getDetalhe () {

    const query: string = `
      getMatriculas(idCurso: ${this.pesquisa.idCurso}, idUsuario: ${this.pesquisa.idUsuario}, idProfessor:${this.pesquisa.idProfessor} ) {
        idMatricula
        idCurso
        idUsuario
        idProfessor
        ptsConclusao
        ptsAcertoAproveitamento
        pesquisaSatisfacao
        ultimoAcesso
        concluido
        degustacao
        cancelada
        titulo
        professor
        aluno
      }
    `

    this.clsApollo.query<Array<getMatriculasInterface>>( this, query, 'getMatriculas', 'Pequisando Matrículas...' ).then( rs => {
      this.registros = rs
    } ).catch( () => {
      this.registros = []
    } )
  }

  private onChangePesquisa (): void {
    this.getDetalhe()
  }

}
