import { Component, Vue } from 'vue-property-decorator'

import { validadoresFrontEnd } from 'zlib-utils'
import { StatusFormType } from '@/utils/dataTypes'

import ClsApolloClient from '../../../utils/ClsApolloClient'

import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'

interface registroInterface {
  novaSenha: string
  confirmarNovaSenha: string
}

@Component
export default class AlterarSenha extends Vue {

  private get token (): string {
    return this.$route.params.token
  }

  private clsApolloClient: ClsApolloClient = new ClsApolloClient()

  private get validadoresFrontEnd (): typeof validadoresFrontEnd {
    return validadoresFrontEnd
  }

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private status: StatusFormType = StatusFormType.inicial
  private formValid = false
  private exibirSenha = false

  private registro: registroInterface = {
    novaSenha: EMDESENVOLVIMENTO ? 'Jacare123' : '',
    confirmarNovaSenha: EMDESENVOLVIMENTO ? 'Jacare123' : ''
  }

  private btResetSenha (): void {

    if ( ( this.$refs.form as Vue & { validate: () => boolean } ).validate() ) {

      const mutation = `
        resetSenha(senha: "${this.registro.novaSenha}", token: "${this.token}") {
          ok
          mensagem
        }
      `

      this.status = StatusFormType.processando

      this.clsApolloClient.mutation<RespostaPadraoInterface>( this, mutation, 'resetSenha', 'Alterando Senha....' ).then( rs => {
        if ( rs.ok ) {
          this.$router.push( "/Login" )
        } else {

          MensagemStoreModule.exibirMensagem( {
            mensagem: rs.mensagem,
            tipo: TipoMensagemType.erro,
            titulo: 'Erro!!!'
          } )

        }
      } )

    }
  }

}