import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
import { EMDESENVOLVIMENTO } from './config/backend/emDesenvolvimento'

import { createProvider } from './vue-apollo'

// TODO - Monitoria do APP
/*
if ( !EMDESENVOLVIMENTO ) {

  Sentry.init( {
    Vue,
    dsn: "https://a1d9e27b70a249638075137935e72313@o1081605.ingest.sentry.io/6089208",
    integrations: [
      new Integrations.BrowserTracing( {
        routingInstrumentation: Sentry.vueRouterInstrumentation( router ),
        tracingOrigins: ["localhost", "my-site-url.com", /^\//],
      } ),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  } );
}
Vue.config.performance = EMDESENVOLVIMENTO

*/

Vue.config.devtools = EMDESENVOLVIMENTO
Vue.config.productionTip = !EMDESENVOLVIMENTO

new Vue( {
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: h => h( App )
} ).$mount( '#app' )
