




























































































































































































































import s from './AulaCls'
export default s
