import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { StatusFormType } from '@/utils/dataTypes'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'
import { UsuarioCadastradoInterface } from '@/interfaces/backend/UsuarioInterfaces'

@Component
export default class GrupoUsuarioCls extends Vue {

  private formValid = false

  private editedItem: Array<number> = []
  private tmpRegistro: Array<number> = []

  private status: StatusFormType = StatusFormType.editando

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private rsUsuarios: Array<UsuarioCadastradoInterface> = []

  @Prop()
  private idGrupo: number

  @Watch( 'idGrupo' )
  private onChangeValue () {
    this.getItems()
  }

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  // v-Select de Usuários

  private isLoading: boolean = false

  private pesquisa: string = ""

  private usuarioSelecionado: UsuarioCadastradoInterface | null = null

  // Usuários que podem ser incluidos
  private listaUsuarios: Array<UsuarioCadastradoInterface> = []

  private btPesquisar (): void {

    this.isLoading = true

    this.listaUsuarios = []

    this.pesquisa = this.pesquisa ? this.pesquisa : ""

    const query: string = `
    getUsuariosSistema(pesquisa: "${this.pesquisa}") {
      idUsuario
      nome
    }
    `
    this.clsApollo.query<Array<UsuarioCadastradoInterface>>( this, query, 'getUsuariosSistema', 'Pesquisando Usuários...' ).then( rs => {
      this.isLoading = false
      this.listaUsuarios = rs.filter( lista => this.rsUsuarios.findIndex( usuario => usuario.idUsuario == lista.idUsuario ) < 0 )
    } )

  }

  private btIncluirUsuario ( qual: UsuarioCadastradoInterface ): void {

    if ( qual && typeof qual == "object" ) {

      const mutation: string = `
        incluirGrupoUsuario(idUsuario: ${qual.idUsuario}, idGrupo: ${this.idGrupo}) {
          ok
          mensagem
        }      
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'incluirGrupoUsuario', 'Incluindo Usuário no Grupo...' ).then( rs => {
        if ( rs.ok ) {
          this.listaUsuarios = this.listaUsuarios.filter( x => x.idUsuario != qual.idUsuario )
          this.rsUsuarios.push( qual )
        } else {

          this.erroRequisicao( 'Erro ao Incluir Usuário do Grupo! Verifique Acesso!' )

        }

      } )

    }
  }

  private getItems (): Promise<boolean> {

    this.rsUsuarios = []

    const query: string = `
      getGrupoUsuario(idGrupo: ${this.idGrupo}) {
        idUsuario
        nome
      }
    `

    return this.clsApollo.query<Array<UsuarioCadastradoInterface>>( this, query, 'getGrupoUsuario', 'Obtendo Usuários Cadastrados...' ).then( rs => {
      this.rsUsuarios = rs
      return true
    } ).catch( () => {
      return false
    } )

  }

  public btCancelar (): void {
    this.editedItem = [...this.tmpRegistro]
  }

  private cabecalhos = [
    { text: 'Usuário', value: 'nome' },
    { text: 'Ações', value: 'actions', sortable: false }
  ]

  private btExcluirUsuario ( qual: UsuarioCadastradoInterface ): void {
    if ( qual ) {

      const mutation: string = `
        excluirGrupoUsuario(idUsuario: ${qual.idUsuario}, idGrupo: ${this.idGrupo}) {
          ok
          mensagem
        }      
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'excluirGrupoUsuario', 'Excluindo Usuário do Grupo...' ).then( rs => {
        if ( rs.ok ) {
          this.rsUsuarios = this.rsUsuarios.filter( x => x.idUsuario != qual.idUsuario )


          //this.listaUsuarios = this.listaUsuarios.filter( x => x.idUsuario != qual.idUsuario )
          this.listaUsuarios.push( qual )

        } else {

          this.erroRequisicao( 'Erro ao Excluir Usuário do Grupo! Verifique Acesso!' )

        }

      } )

    }
  }

  private erroRequisicao ( mensagem: string ): void {
    this.listaUsuarios = []
    this.getItems().finally( () => {

      MensagemStoreModule.exibirMensagem( {
        mensagem: mensagem,
        tipo: TipoMensagemType.erro,
        titulo: 'Erro'

      } )

    } )

  }

}