














































































import s from './UsuarioPermissaoCls'
export default s
