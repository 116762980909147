






































































import s from './cardCursoCls'
export default s
