import { Component, Vue } from 'vue-property-decorator'

import ClsUtils, { validadoresFrontEnd } from 'zlib-utils'
import { StatusFormType } from '@/utils/dataTypes'

import ClsApolloClient from '../../../utils/ClsApolloClient'
import { LoginStoreModule } from '@/store/modules/LoginStore'

import { AUTH_TOKEN } from '../../../vue-apollo'
import { LoginInputInterface, UsuarioLogadoInterface } from '@/interfaces/backend/LoginInterfaces'
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento'
import { PAGINA_INICIAL_EMDESENVOLVIMENTO, PAGINA_INICIAL_PADRAO } from '@/config/Menu'
import store from '@/store'

@Component
export default class app extends Vue {

  private clsApolloClient: ClsApolloClient = new ClsApolloClient()

  private mounted () {

    const tmpToken: string = localStorage.getItem( AUTH_TOKEN ) || ''

    if ( tmpToken && !LoginStoreModule.logado ) {
      this.logarPorToken()
    }

  }

  private get validadoresFrontEnd (): typeof validadoresFrontEnd {
    return validadoresFrontEnd
  }

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private status: StatusFormType = StatusFormType.inicial
  private formValid = false
  private exibirSenha = false

  private registro: LoginInputInterface = {

    // TODO - Login em Desenvolvimento
    login: EMDESENVOLVIMENTO ? 'Fleek' : '',
    senha: EMDESENVOLVIMENTO ? 'SenhaFleek2020' : ''
    // login: EMDESENVOLVIMENTO ? 'Zanatta' : '',
    //senha: EMDESENVOLVIMENTO ? 'Teste123' : ''

  }

  private logarPorToken (): void {
    const mutation = `
      logarPorToken {
        ok
        mensagem
        nome
        token
        tipo
        idUsuario
      }
    `

    this.status = StatusFormType.processando

    this.clsApolloClient.mutation<UsuarioLogadoInterface>( this, mutation, 'logarPorToken', 'Verificando Login...' ).then( rs => {

      this.loginOK( rs )

    } )

  }

  private logar (): void {

    const clsUtils: ClsUtils = new ClsUtils()

    if ( ( this.$refs.form as Vue & { validate: () => boolean } ).validate() ) {

      const logar = `
        logar (login: ${clsUtils.ConverterEmGql( this.registro )}) {
            ok
            mensagem
            nome
            tipo
            idUsuario
            token
          }
      `

      this.status = StatusFormType.processando

      this.clsApolloClient.mutation<UsuarioLogadoInterface>( this, logar, 'logar', 'Verificando Usuário e Senha...' ).then( rs => {


        this.loginOK( rs )

      } )

    }
  }

  private loginOK ( usuarioLogado: UsuarioLogadoInterface ): void {

    if ( usuarioLogado.ok ) {

      localStorage.setItem( AUTH_TOKEN, usuarioLogado.token )

      store.dispatch( 'getPermissoes', this ).then( () => {

        store.dispatch( 'logar', usuarioLogado ).then( () => {

          store.dispatch( 'updateAll', this ).then( () => {

            if ( EMDESENVOLVIMENTO && PAGINA_INICIAL_EMDESENVOLVIMENTO.length > 0 ) {
              this.$router.push( PAGINA_INICIAL_EMDESENVOLVIMENTO )
            } else {
              this.$router.push( PAGINA_INICIAL_PADRAO )
            }
          } )

        } )

      } )

    } else {

      this.status = StatusFormType.inicial

    }

  }

}