import ClsApolloClient from '@/utils/ClsApolloClient'
import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '..'

interface UpdateAulaInterface { idMatricula: number, idAula: number, idCurso: number }

export enum STATUS_STORE_MUTATION {
  updateAula = 'updateAula'
}

/*
export enum STATUS_STORE_ACTION {
  proximaAula = 'proximaAula'
}
*/

export interface StateStatusInterface {
  idAula: number
  idMatricula: number
  idCurso: number
  // [STATUS_STORE_ACTION.proximaAula] (): Promise<number>
  [STATUS_STORE_MUTATION.updateAula] ( { idMatricula, idAula, idCurso }: UpdateAulaInterface ): void
}

// @Module( { namespaced: true, name: 'login' } )
@Module( { dynamic: true, store, name: 'status' } )
class StatusStore extends VuexModule implements StateStatusInterface {

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private _idAula = 0
  private _idMatricula = 0
  private _idCurso = 0

  public get idAula (): number {
    return this._idAula
  }

  public get idMatricula (): number {
    return this._idMatricula
  }

  public get idCurso (): number {
    return this._idCurso
  }

  @Mutation
  [STATUS_STORE_MUTATION.updateAula] ( { idMatricula, idAula, idCurso }: UpdateAulaInterface ): void {

    this._idAula = idAula
    this._idMatricula = idMatricula
    this._idCurso = idCurso

  }
  /*
    @Action( { commit: STATUS_STORE_MUTATION.updateAula } )
    [STATUS_STORE_ACTION.proximaAula] (): Promise<number> {
  
      return new Promise<number>( ( resolve ) => {
        if ( this._idMatricula && this._idCurso ) {
          const query: string = `
            proximaAula(idMatricula: ${this.idMatricula}, idCurso: ${this.idCurso})
          `
  
          // console.log(query)
          this.clsApollo.query<number>( this, query, 'proximaAula' ).then( rs => {
            if ( rs ) {
              resolve( rs )
              // console.log( 'Dentro do RS OK, proxima aula....', rs )
              // this.$router.push( '/play/'.concat( this.idMatricula.toString() ).concat( '/' ).concat( this.idCurso.toString() ).concat( '/' ).concat( rs.toString() ) )
              // } else {
              // this.$router.push( '/MeusCursos' )
            } else {
              resolve( 0 )
            }
          } )
        } else {
          resolve( 0 )
        }
      } )
  
    }
    */

}

export const StatusStoreModule: StateStatusInterface = getModule( StatusStore, store )