
export default new class ClsHandleErroAPP {

  public handle ( erro: Error, arquivoFonte: string, procedimento: string ): void {
    // TODO - Colocar Envio de Erro para Suporte....
    console.log( 'ERRO NA APLICAÇÃO' )
    console.log( 'Arquivo Fonte: ', arquivoFonte )
    console.log( 'Procedimento: ', procedimento )
    console.log( 'Mensagem: ', erro.message, 'Nome: ', erro.name, 'Stack: ', erro.stack )
  }

}()