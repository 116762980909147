






































import Vue from 'vue'
import Component from 'vue-class-component'

import PlayExercicio from '@/views/alunos/PlayExercicio.vue'
import { rsPlayAulaExercicioInterface } from '@/interfaces/backend/PlayInterfaces'

@Component( {
  components: {
    PlayExercicio
  }
} )
export default class Teste extends Vue {

  private rsExercicios: Array<rsPlayAulaExercicioInterface> = [{ "idAulaExercicio": 2, "idAula": 1, "titulo": "Tipo de Exame", "propriedades": "[{\"alinhamento\":\"left\",\"elementoHTML\":\"p\",\"texto\":{\"conteudo\":\"<p>De acordo com a imagem abaixo, podemos dizer que o tipo do exame é:</p>\"}},{\"alinhamento\":\"center\",\"elementoHTML\":\"img\",\"imagem\":{\"idAulaMidia\":7,\"height\":\"250px\",\"maxHeight\":150,\"maxWidth\":150,\"width\":100}},{\"alinhamento\":\"left\",\"elementoHTML\":\"input-teste\",\"teste\":{\"opcoes\":[\"Radiografia\",\"Tomografia Computadorizada\",\"Exame de Sangue\",\"Exame de Urina\"],\"opcoesCorretas\":[0,1],\"ptsTeste\":10}},{\"alinhamento\":\"center\",\"elementoHTML\":\"input-teste\",\"teste\":{\"opcoes\":[\"Novo Teste\",\"caca\"],\"opcoesCorretas\":[0],\"ptsTeste\":18}}]", "ptsAproveitamento": 0, "ordem": 3, "idMatricula": 0, "idCurso": 0, "idUsuario": 0, "idCursoAssunto": 0, "idCursoAssuntoTrilha": 0, "resposta": "", "ptsAcertoAproveitamento": 0, "concluido": false }]

}
