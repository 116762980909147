import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { StatusFormType } from '@/utils/dataTypes'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { SistemaModuloPermissaoInterface } from '@/interfaces/backend/SistemaModuloPermissaoInterfaces'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'

@Component
export default class GrupoPermissaoCls extends Vue {

  private formValid = false

  private editedItem: Array<number> = []
  private tmpRegistro: Array<number> = []

  private status: StatusFormType = StatusFormType.editando

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  //Permissões que estão cadastrados
  private rsPermissoes: Array<SistemaModuloPermissaoInterface> = []

  @Prop()
  private idGrupo: number

  @Watch( 'idGrupo' )
  private onChangeValue () {
    this.getItems()
  }

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  // v-Select de Permissões

  private isLoading: boolean = false

  private pesquisa: string = ""

  private permissaoSelecionada: SistemaModuloPermissaoInterface | null = null

  // Permissões que podem ser incluidos
  private listPermissoes: Array<SistemaModuloPermissaoInterface> = []

  private btPesquisar (): void {

    this.isLoading = true

    this.listPermissoes = []

    this.pesquisa = this.pesquisa ? this.pesquisa : ""

    const query: string = `
      getSistemaModuloPermissoes(pesquisa: "${this.pesquisa}") {
        idSistemaPermissao
        descricao
      }
    `

    this.clsApollo.query<Array<SistemaModuloPermissaoInterface>>( this, query, 'getSistemaModuloPermissoes', 'Pesquisando Permissões...' ).then( rs => {
      this.isLoading = false
      this.listPermissoes = rs.filter( lista => this.rsPermissoes.findIndex( permissao => permissao.idSistemaPermissao == lista.idSistemaPermissao ) < 0 )
    } )

  }

  private btIncluirPermissao ( qual: SistemaModuloPermissaoInterface ): void {

    if ( qual && typeof qual == "object" ) {

      const mutation: string = `
        incluirGrupoPermissao(idSistemaPermissao: ${qual.idSistemaPermissao}, idGrupo: ${this.idGrupo}) {
          ok
          mensagem
        }      
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'incluirGrupoPermissao', 'Incluindo Permissão no Grupo...' ).then( rs => {
        if ( rs.ok ) {
          this.listPermissoes = this.listPermissoes.filter( x => x.idSistemaPermissao != qual.idSistemaPermissao )
          this.rsPermissoes.push( qual )
        } else {

          this.erroRequisicao( 'Erro ao Incluir Permissão do Grupo! Verifique Acesso!' )

        }

      } )

    }
  }

  private getItems (): Promise<boolean> {

    this.rsPermissoes = []

    const query: string = `
      getGrupoPermissao(idGrupo: ${this.idGrupo}) {
        idSistemaPermissao
        descricao
      }
    `

    return this.clsApollo.query<Array<SistemaModuloPermissaoInterface>>( this, query, 'getGrupoPermissao', 'Obtendo Permissões Cadastrados...' ).then( rs => {
      this.rsPermissoes = rs
      return true
    } ).catch( () => {
      return false
    } )

  }

  public btCancelar (): void {
    this.editedItem = [...this.tmpRegistro]
  }

  private cabecalhos = [
    { text: 'Permissão', value: 'descricao' },
    { text: 'Ações', value: 'actions', sortable: false }
  ]

  private btExcluirPermissao ( qual: SistemaModuloPermissaoInterface ): void {
    if ( qual ) {

      const mutation: string = `
        excluirGrupoPermissao(idSistemaPermissao: ${qual.idSistemaPermissao}, idGrupo: ${this.idGrupo}) {
          ok
          mensagem
        }      
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'excluirGrupoPermissao', 'Excluindo Permissão do Grupo...' ).then( rs => {
        if ( rs.ok ) {
          this.rsPermissoes = this.rsPermissoes.filter( x => x.idSistemaPermissao != qual.idSistemaPermissao )
          this.listPermissoes.push( qual )

        } else {

          this.erroRequisicao( 'Erro ao Excluir Permissão do Grupo! Verifique Acesso!' )

        }

      } )

    }
  }

  private erroRequisicao ( mensagem: string ): void {
    this.listPermissoes = []
    this.getItems().finally( () => {

      MensagemStoreModule.exibirMensagem( {
        mensagem: mensagem,
        tipo: TipoMensagemType.erro,
        titulo: 'Erro'

      } )

    } )

  }

}