import { Component, Vue } from 'vue-property-decorator'

import { ENDPOINT, TAMANHO_MAXIMO_SELFIE } from '@/config/backend/emDesenvolvimento'
import { LoginStoreModule } from '@/store/modules/LoginStore'
import axios from 'axios'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'


@Component
export default class app extends Vue {

  private keyRefresh: number = 1

  private imagemSelfie: string = ''
  private midiaUpload: File | null = null

  private emEdicao: boolean = false

  private mounted () {
    this.getSelfie()
  }

  private btClickUploadSelfie (): void {
    document.getElementById( 'fileInputSelfie' )?.click()
  }

  private onChangeImage (): void {

    const fileInputSelfie: HTMLElement | null | HTMLInputElement = document.getElementById( 'fileInputSelfie' )

    if ( fileInputSelfie instanceof HTMLInputElement && fileInputSelfie.files ) {

      if ( fileInputSelfie.files[0].size > TAMANHO_MAXIMO_SELFIE ) {
        MensagemStoreModule.exibirMensagem( {
          mensagem: 'Tamanho do Arquivo Maior que limite: '.concat( ( TAMANHO_MAXIMO_SELFIE / 1024 ).toString() ).concat( 'Kb' ),
          tipo: TipoMensagemType.aviso,
          titulo: 'Aviso!'
        } )
      } else {
        this.imagemSelfie = URL.createObjectURL( fileInputSelfie.files[0] )
        this.emEdicao = true
      }

    }

  }

  private getSelfie (): void {

    if ( LoginStoreModule.token ) {

      axios.post( ENDPOINT.concat( 'getSelfie' ), {}, {
        responseType: 'json',
        headers: {
          'content-type': 'multipart/form-data',
          'authorization': LoginStoreModule.token
        }
      } ).then( rs => {
        this.imagemSelfie = rs.data.link
        this.keyRefresh++
      } )
    }
  }

  private btFileInputSelfie (): void {

    const url: string = ENDPOINT.concat( 'uploadSelfie' )

    if ( this.midiaUpload ) {

      MensagemStoreModule.exibirMensagem( {
        mensagem: 'Upload, aguarde....',
        tipo: TipoMensagemType.aguarde,
        titulo: 'Processando'
      } )

      const dados: FormData = new FormData()

      dados.append( 'nomeArquivo', this.midiaUpload )
      dados.append( 'token', LoginStoreModule.token )

      axios.post( url, dados, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      } ).then( rs => {

        if ( !rs.data.ok ) {
          MensagemStoreModule.exibirMensagem( {
            mensagem: rs.data.mensagem,
            tipo: TipoMensagemType.erro,
            titulo: 'Erro'
          } )
        } else {
          this.emEdicao = false
          MensagemStoreModule.ocultarMensagem()
          this.keyRefresh++
        }
      } )

    }
  }

}