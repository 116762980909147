import { Component, Prop, Vue } from 'vue-property-decorator'

import { clsUtils, validadoresFrontEnd } from 'zlib-utils'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { StatusFormType } from '@/utils/dataTypes'
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'
import { CursoAssuntoInterface } from '@/interfaces/backend/CursoInterfaces'

import CursoAssuntoTrilha from '@/views/curso/CursoAssuntoTrilha.vue'

@Component( {
  components: {
    CursoAssuntoTrilha
  }
} )
export default class CursoAssuntoCls extends Vue {

  // private pesquisa: string = ''

  @Prop( {
    default: 0
  } )
  private idCurso: number

  @Prop( {
    default: ''
  } )
  private titulo: string

  private validadoresFrontEnd = validadoresFrontEnd
  private formValid = false

  private editedItem: Partial<CursoAssuntoInterface> = this.resetModel()
  private tmpRegistro: Partial<CursoAssuntoInterface> = this.resetModel()

  private registros: Array<CursoAssuntoInterface> = []

  private resetModel (): Partial<CursoAssuntoInterface> {
    return {
      idCurso: this.idCurso,
      titulo: '',
      descricao: '',
      ordem: 0
    }
  }
  private status: StatusFormType = StatusFormType.inicial

  private tituloForm: string = "Assuntos"

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private mounted () {
    this.getDetalhe()
  }

  private btCancelar (): void {
    this.status = StatusFormType.inicial
  }

  private btIncluir (): void {

    this.tituloForm = 'Incluir Assunto'

    this.status = StatusFormType.editando

    if ( EMDESENVOLVIMENTO ) {
      this.editedItem = {
        idCurso: this.idCurso,
        descricao: 'Assunto Teste 01',
        titulo: 'Titulo Assunto Teste 01',
        ordem: this.editedItem.ordem = this.registros.length + 1
      }
    } else {
      this.editedItem = this.resetModel()
      this.editedItem.ordem = this.registros.length + 1
    }
  }

  private btConfirmar (): void {

    if ( this.status == StatusFormType.editando ) {

      if ( ( this.$refs.form as Vue & { validate: () => boolean } ).validate() ) {

        const mutation: string = `
            updateCursoAssunto(dados: ${clsUtils.ConverterEmGql( this.editedItem )} ) {
              ok
              mensagem
            }
          `

        this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'updateCursoAssunto', 'Atualizando Assunto de Curso' ).then( rs => {
          if ( rs.ok ) {
            this.status = StatusFormType.inicial
            this.getDetalhe()
          }
        } )

      }
    } else if ( this.status == StatusFormType.excluindo ) {

      const mutation: string = `
        delCursoAssunto(idCursoAssunto: ${this.editedItem.idCursoAssunto} ) {
          ok
          mensagem
        }
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'delCursoAssunto', 'Excluindo Assunto do Curso....' ).then( rs => {
        if ( rs.ok ) {
          this.status = StatusFormType.inicial
          this.getDetalhe()
        } else {

          MensagemStoreModule.exibirMensagem( {
            mensagem: rs.mensagem,
            tipo: TipoMensagemType.erro,
            titulo: 'Erro!!!'
          } )

        }
      } )

    }
  }

  private cabecalhos = [
    { text: 'Título', value: 'titulo' },
    { text: 'Descrição', value: 'descricao' },
    { text: 'Ordem', value: 'ordem' },
    { text: 'Ações', value: 'actions', sortable: false }
  ]

  private viewItem ( item: CursoAssuntoInterface ) {
    this.tituloForm = "Visualizar Assunto"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.exibindo
  }

  private editItem ( item: CursoAssuntoInterface ) {
    this.tituloForm = "Alterar Assunto"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.editando
  }

  private deleteItem ( item: CursoAssuntoInterface ) {
    this.tituloForm = "Excluir Assunto"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.excluindo
  }

  private getDetalhe () {

    const query: string = `
      getCursoAssuntos(idCurso: ${this.idCurso}) {
        idCursoAssunto
        idCurso
        titulo
        descricao
        ordem
      }
    `

    this.clsApollo.query<Array<CursoAssuntoInterface>>( this, query, 'getCursoAssuntos', 'Pequisando Assuntos...' ).then( rs => {
      this.registros = rs
    } ).catch( () => {
      this.registros = []
    } )
  }

  private btPesquisar (): void {
    this.getDetalhe()
  }

  // Drag And Drop

  private startDrag ( evt: DragEvent, idCursoAssunto: number, ordem: number ) {

    if ( evt.dataTransfer ) {
      // console.log('START DRAG')
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData( 'idCursoAssuntoDrag', idCursoAssunto.toString() )
      evt.dataTransfer.setData( 'ordemDrag', ordem.toString() )
    }

  }

  private onDrop ( evt: DragEvent, idCursoAssuntoDrop: number, ordemDrop: number ) {

    if ( evt.dataTransfer ) {

      const idCursoAssuntoDrag = parseInt( evt.dataTransfer.getData( 'idCursoAssuntoDrag' ) )
      const ordemDrag = parseInt( evt.dataTransfer.getData( 'ordemDrag' ) )

      this.dragAndDrop( idCursoAssuntoDrag, ordemDrag, idCursoAssuntoDrop, ordemDrop )

    }
  }

  private dragAndDrop ( idCursoAssuntoDrag: number, ordemDrag: number, idCursoAssuntoDrop: number, ordemDrop: number ): void {

    const mutation: string = `
        trocarOrdemCursoAssunto(idCursoAssuntoDrag: ${idCursoAssuntoDrag}, ordemDrag: ${ordemDrag}, idCursoAssuntoDrop: ${idCursoAssuntoDrop}, ordemDrop: ${ordemDrop}) {
          ok
          mensagem
        }      
      `

    this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'trocarOrdemCursoAssunto', 'Alterando Ordem do Conteúdo...' ).then( rs => {
      if ( rs.ok ) {

        this.getDetalhe()

      } else {

        MensagemStoreModule.exibirMensagem( {
          mensagem: rs.mensagem,
          tipo: TipoMensagemType.erro,
          titulo: 'Erro'

        } )

      }

    } )
  }

}
