import { ExercicioConteudoInterface } from "@/types/backend/ExerciciosDataType"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extrairEstilo = ( propriedades: Record<string, unknown>, propriedade: string, css: string ) => Object.prototype.hasOwnProperty.call( propriedades, propriedade ) ? css.concat( ':' ).concat( ( propriedades as any )[propriedade] ).concat( ';' ) : ''

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extrairClasse = ( propriedades: ExercicioConteudoInterface, propriedade: string, valor: string ) => Object.prototype.hasOwnProperty.call( propriedades, propriedade ) ? valor.concat( ( propriedades as any )[propriedade] ).concat( ' ' ) : ''

export default class ClsEstilo {

  public getStyle ( propriedades: Record<string, unknown> ): string {

    const retorno =
      // extrairEstilo( propriedades.imagem, 'marginTop', 'margin-top' ) +
      // extrairEstilo( propriedades.imagem, 'marginRight', 'margin-right' ) +
      // extrairEstilo( propriedades.imagem, 'marginBottom', 'margin-bottom' ) +
      // extrairEstilo( propriedades.imagem, 'marginLeft', 'margin-left' ) +
      extrairEstilo( propriedades, 'maxWidth', 'max-width' ) +
      extrairEstilo( propriedades, 'maxHeight', 'max-height' ) +
      extrairEstilo( propriedades, 'width', 'width' ) +
      extrairEstilo( propriedades, 'height', 'height' )

    return retorno

  }

  public getClass ( propriedades: ExercicioConteudoInterface ): string {

    const retorno =
      extrairClasse( propriedades, 'alinhamento', 'text-' )
      /* +
      extrairClasse( propriedades, 'textClass', 'text-' ) +
      extrairClasse( propriedades, 'fontWeight', 'font-weight-' )
      */
    return retorno

  }

  public getCols ( propriedades: string | Record<string, unknown>, grade: string ): string {

    /* eslint-disable-next-line */
    const getRetorno = ( propriedade: string, padrao: string ) => Object.prototype.hasOwnProperty.call( propriedades, propriedade ) ? ( propriedades as any )[propriedade] : padrao

    /* eslint-disable-next-line */
    const retorno: any = {}

    retorno.xs = getRetorno( 'xs', '12' )
    retorno.sm = getRetorno( 'sm', retorno.xs )
    retorno.md = getRetorno( 'md', retorno.sm )
    retorno.lg = getRetorno( 'lg', retorno.md )
    retorno.xl = getRetorno( 'xl', retorno.lg )

    return retorno[grade]
  }

}