import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { StatusFormType } from '@/utils/dataTypes'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'
import { AulaTagInterface } from '@/interfaces/backend/AulaInterfaces'

@Component
export default class AulaTagCls extends Vue {

  private formValid = false

  private editedItem: Array<number> = []
  private tmpRegistro: Array<number> = []

  private status: StatusFormType = StatusFormType.editando

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private rsTags: Array<AulaTagInterface> = []

  @Prop()
  private idAula: number

  @Watch( 'idAula' )
  private onChangeValue () {
    this.getItems()
  }

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  // v-Select de Usuários

  private isLoading: boolean = false

  private pesquisa: string = ""

  private tagSelecionada: AulaTagInterface | null = null

  private listaTags: Array<AulaTagInterface> = []

  private btPesquisar (): void {

    this.isLoading = true

    this.listaTags = []

    this.pesquisa = this.pesquisa ? this.pesquisa : ""

    const query: string = `
      getTagsUtilizadas(pesquisa: "${this.pesquisa}") {
        tag
      }
    `
    this.clsApollo.query<Array<AulaTagInterface>>( this, query, 'getTagsUtilizadas', 'Pesquisando Tags...' ).then( rs => {

      if ( rs.length > 0 ) {
        this.isLoading = false
        this.listaTags = rs.filter( lista => this.rsTags.findIndex( tag => tag.tag == lista.tag ) < 0 )
      } else {
        this.isLoading = false
        this.btIncluirNovaTag()
      }
    } )

  }

  private btIncluirNovaTag (): void {
    if ( this.pesquisa.length > 0 ) {
      const mutation: string = `
        incluirAulaTag(idAula: ${this.idAula}, tag: "${this.pesquisa}") {
          ok
          mensagem
        }      
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'incluirAulaTag', 'Incluindo Tag na Aula...' ).then( rs => {
        if ( rs.ok ) {
          this.getItems()
          this.listaTags = this.listaTags.filter( x => x.tag != this.pesquisa )
          this.pesquisa = ""
        } else {

          this.erroRequisicao( 'Erro ao Incluir Tag da Aula! Verifique Acesso!' )

        }

      } )

    }
  }

  private btIncluirTagExistente ( qual: AulaTagInterface ): void {

    if ( qual && typeof qual == "object" ) {

      const mutation: string = `
        incluirAulaTag(idAula: ${this.idAula}, tag: "${qual.tag}") {
          ok
          mensagem
        }      
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'incluirAulaTag', 'Incluindo Tag na Aula...' ).then( rs => {
        if ( rs.ok ) {
          this.getItems()
          this.listaTags = this.listaTags.filter( x => x.tag != qual.tag )
        } else {

          this.erroRequisicao( 'Erro ao Incluir Tag da Aula! Verifique Acesso!' )

        }

      } )

    }
  }

  private getItems (): Promise<boolean> {

    this.rsTags = []

    const query: string = `
      getAulaTag(idAula: ${this.idAula}) {
        idAulaTag
        tag
      }
    `

    return this.clsApollo.query<Array<AulaTagInterface>>( this, query, 'getAulaTag', 'Obtendo Tags da Aula...' ).then( rs => {
      this.rsTags = rs
      return true
    } ).catch( () => {
      return false
    } )

  }

  public btCancelar (): void {
    this.editedItem = [...this.tmpRegistro]
  }

  private cabecalhos = [
    { text: 'Tag', value: 'tag' },
    { text: 'Ações', value: 'actions', sortable: false }
  ]

  private btExcluirTag ( qual: AulaTagInterface ): void {
    if ( qual ) {

      const mutation: string = `
        excluirAulaTag(idAulaTag: ${qual.idAulaTag}) {
          ok
          mensagem
        }      
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'excluirAulaTag', 'Excluindo Tag da Aula...' ).then( rs => {
        if ( rs.ok ) {

          this.rsTags = this.rsTags.filter( x => x.idAulaTag != qual.idAulaTag )
          this.listaTags.push( qual )
          this.pesquisa = qual.tag

        } else {

          this.erroRequisicao( 'Erro ao Excluir Tag da Aula! Verifique Acesso!' )

        }

      } )

    }
  }

  private erroRequisicao ( mensagem: string ): void {
    this.listaTags = []
    this.getItems().finally( () => {

      MensagemStoreModule.exibirMensagem( {
        mensagem: mensagem,
        tipo: TipoMensagemType.erro,
        titulo: 'Erro'

      } )

    } )

  }

}