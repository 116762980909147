import { Component, Vue } from 'vue-property-decorator'

import { validadoresFrontEnd } from 'zlib-utils'
import { StatusFormType } from '@/utils/dataTypes'

import ClsApolloClient from '../../../utils/ClsApolloClient'
import { LoginStoreModule } from '@/store/modules/LoginStore'

import { UsuarioLogadoInterface } from '@/interfaces/backend/LoginInterfaces'

@Component
export default class EsqueciSenhaCls extends Vue {

  private clsApolloClient: ClsApolloClient = new ClsApolloClient()

  private get validadoresFrontEnd (): typeof validadoresFrontEnd {
    return validadoresFrontEnd
  }

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private status: StatusFormType = StatusFormType.inicial
  private formValid = false
  private exibirSenha = false

  private email: string = ""

  private btEsqueciSenha (): void {
    const mutation = `
      esqueciSenha(email: "${this.email}" ) {
        ok
        mensagem
      }
    `

    this.status = StatusFormType.processando

    this.clsApolloClient.mutation<UsuarioLogadoInterface>( this, mutation, 'esqueciSenha', 'Verificando e-mail...' ).then( rs => {

      this.logout()

    } )

  }

  private logout (): void {

    if ( LoginStoreModule.logado ) {

      const clsApolloClient: ClsApolloClient = new ClsApolloClient()

      const logout = `
      logout {
        ok
        mensagem
      }
      `

      this.status = StatusFormType.processando

      clsApolloClient.mutation<UsuarioLogadoInterface>( this, logout, 'logout', 'Saindo da Aplicação....' ).then( rs => {

        if ( rs.ok ) {
          LoginStoreModule.logout()
        }

      } )

    }

    this.$router.push( '/Login' )

  }

}