import { EMDESENVOLVIMENTO } from "@/config/backend/emDesenvolvimento"
import { AlunoCursoTypeInterface } from "@/interfaces/backend/AlunoInterfaces"

const DEBUG_CLS_CURSO: boolean = EMDESENVOLVIMENTO && false

export class ClsCalculos {

  private rsCurso: AlunoCursoTypeInterface

  public constructor( rsCurso: AlunoCursoTypeInterface ) {
    this.rsCurso = rsCurso
    DEBUG_CLS_CURSO ? console.log('rsCurso dentro de CLSCalculos: ',JSON.stringify(rsCurso)) : ""
  }

  public get progresso (): number {

    return this.rsCurso.totConclusao > 0 ? this.rsCurso.ptsConclusao / this.rsCurso.totConclusao * 100 : 0

  }

  public get aproveitamento (): number {

    return this.rsCurso.totAproveitamento > 0 ? this.rsCurso.ptsAcertoAproveitamento / this.rsCurso.totAproveitamento * 100 : 0

  }
}