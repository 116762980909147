import { UsuarioLogadoInterface } from '@/interfaces/backend/LoginInterfaces'
import { PermissoesInterface } from '@/interfaces/backend/SistemaModuloPermissaoInterfaces'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '..'

export interface StateLoginInterface {
  idUsuario: number;
  logado: boolean;
  nome: string;
  token: string;
  permissoes: Array<PermissoesInterface>;
  refreshLogin: number;
  login ( usuarioLogado: UsuarioLogadoInterface ): void;
  updatePermissoes ( permissoes: Array<PermissoesInterface> ): void;
  logout (): void;
  setToken ( token: string ): void;
  logar ( usuarioLogado: UsuarioLogadoInterface ): UsuarioLogadoInterface;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  getPermissoes ( payload: any ): Promise<Array<PermissoesInterface>>
}

@Module( { dynamic: true, store, name: 'loginStore' } )
class LoginStore extends VuexModule implements StateLoginInterface {

  idUsuario = 0
  logado = false
  nome = ''
  token = ''
  permissoes: Array<PermissoesInterface> = []
  refreshLogin = 0

  @Mutation
  login ( usuarioLogado: UsuarioLogadoInterface ): void {
    this.idUsuario = usuarioLogado.idUsuario
    this.logado = true
    this.nome = usuarioLogado.nome
    this.token = usuarioLogado.token
    this.refreshLogin++
  }

  @Mutation
  updatePermissoes ( permissoes: Array<PermissoesInterface> ): void {

    this.permissoes = permissoes
  }

  @Mutation
  logout (): void {
    this.idUsuario = 0
    this.logado = false
    this.nome = ''
    this.token = ''
  }

  @Mutation
  setToken ( token: string ): void {
    this.token = token
  }

  @Action( { commit: 'login' } )
  logar ( usuarioLogado: UsuarioLogadoInterface ): UsuarioLogadoInterface {
    return usuarioLogado
  }

  @Action( { commit: 'updatePermissoes' } )
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  getPermissoes ( payload: any ): Promise<Array<PermissoesInterface>> {

    const clsApolloClient: ClsApolloClient = new ClsApolloClient()

    const query: string = `
      getPermissoes {
        modulo
        permissao
      }
    `

    return clsApolloClient.query<Array<PermissoesInterface>>( payload, query, 'getPermissoes' ).then( rs => {

      return rs
    } ).catch( () => {
      return []
    } )

  }

}

export const LoginStoreModule: StateLoginInterface = getModule( LoginStore, store )