import { AulaMidiaInterface } from '@/interfaces/backend/AulaInterfaces';
import ClsEstilo from '@/utils/ClsEstilo';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import CadastroTeste from '@/components/teste/CadastroTeste.vue'
import CadastroCompletar from '@/components/completar/CadastroCompletar.vue'
import { elementoHTMLType, ExercicioConteudoInterface } from '@/types/backend/ExerciciosDataType'

import { VueEditor } from "vue2-editor"

@Component( {
  components: {
    CadastroCompletar,
    CadastroTeste,
    VueEditor
  }
} )
export default class ConteudoEditarCls extends Vue {

  private tmpClsEstilo: ClsEstilo = new ClsEstilo()

  private get clsEstilo (): ClsEstilo {
    return this.tmpClsEstilo
  }

  private get elementoHTMLType (): typeof elementoHTMLType {
    return elementoHTMLType
  }

  private get customToolbarVueEditor (): Array<Array<string | Record<string, unknown>>> {
    return [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: "center" }, { align: "" }, { align: "right" }, { align: "justify" }],
      ["code-block"],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme,

    ]
  }

  @Prop( {
    default: () => { return {} }
  } )
  private exercicioConteudo: Partial<ExercicioConteudoInterface>

  @Prop( {
    default: () => { return [] }
  } )
  private rsMidias: Array<AulaMidiaInterface>

  @Prop( {
    default: -1
  } )
  private chave: number

  @Watch( 'chave' )
  private changeItem () {
    if ( this.exercicioConteudo ) {
      this.edicao = JSON.parse( JSON.stringify( this.exercicioConteudo ) )
      this.tmpConteudoSemEdicao = JSON.parse( JSON.stringify( this.exercicioConteudo ) )
    }
  }

  private edicao: Partial<ExercicioConteudoInterface> = {}

  private tmpConteudoSemEdicao: Partial<ExercicioConteudoInterface> = {}

  private confirmarEdicao (): void {

    if ( this.edicao.teste && this.edicao.teste.ptsTeste ) {
      this.edicao.teste.ptsTeste = parseInt( this.edicao.teste.ptsTeste.toString() )
    }

    this.$emit( 'confirmarEdicaoExercicio', this.edicao )

  }

  private cancelarEdicao (): void {

    if ( this.tmpConteudoSemEdicao ) {
      this.edicao = { ...this.tmpConteudoSemEdicao }
    }

    this.$emit( 'cancelarEdicaoExercicio' )
  }

  private excluirExercicio (): void {
    this.$emit( 'confirmarExcluirExercicio' )
  }

}