import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { StatusFormType } from '@/utils/dataTypes'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'
import { AulaMidiaInterface } from '@/interfaces/backend/AulaInterfaces'
import { ENDPOINT } from '@/config/backend/emDesenvolvimento'
import { LoginStoreModule } from '@/store/modules/LoginStore'
import axios from 'axios'
import { AulaMidiaType } from '@/types/backend/AulaMidiaDataType'

@Component
export default class AulaMidiaCls extends Vue {

  private status: StatusFormType = StatusFormType.editando

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private rsMidias: Array<AulaMidiaInterface> = []

  private tituloForm: string = ''

  @Prop()
  private idAula: number

  @Prop()
  private tipo: AulaMidiaType

  @Watch( 'idAula' )
  private onChangeValue () {
    this.getItems()

    if ( this.tipo == AulaMidiaType.videoAula ) {
      this.tituloForm = 'Vídeo da Aula'
    } else if ( this.tipo == AulaMidiaType.download ) {
      this.tituloForm = 'Materiais para Downloads'
    } else {
      this.tituloForm = 'Arquivos de Exercícios'
    }

  }

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private get AulaMidiaType (): typeof AulaMidiaType {
    return AulaMidiaType
  }

  private get extensoesValidas (): string {
    return this.tipo == AulaMidiaType.videoAula ? "video/mp4" : this.tipo == AulaMidiaType.exercicio ? "image/png, image/jpg, image/jpeg" : "video/mp4, image/png, image/jpg, image/jpeg, .pdf"
  }

  // v-Select de Usuários

  private getItems (): Promise<boolean> {

    this.rsMidias = []

    const query: string = `
      getAulaMidias(idAula: ${this.idAula} tipo: ${this.tipo}) {
        idAulaMidia
        nomeArquivo
      }
    `

    return this.clsApollo.query<Array<AulaMidiaInterface>>( this, query, 'getAulaMidias', 'Carregando Mídias...' ).then( rs => {
      this.rsMidias = rs
      return true
    } ).catch( () => {
      return false
    } )

  }

  private cabecalhos = [
    { text: 'Arquivo', value: 'nomeArquivo' },
    { text: 'Ações', value: 'actions', sortable: false }
  ]

  private btExcluirAulaMidia ( qual: AulaMidiaInterface ): void {
    if ( qual ) {

      const mutation: string = `
        excluirAulaMidia(idAulaMidia: ${qual.idAulaMidia}) {
          ok
          mensagem
        }      
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'excluirAulaMidia', 'Excluindo Mídia da Aula...' ).then( rs => {
        if ( rs.ok ) {

          this.rsMidias = this.rsMidias.filter( x => x.idAulaMidia != qual.idAulaMidia )

        } else {

          this.erroRequisicao( 'Erro ao Excluir Mídia da Aula! Verifique Acesso!' )

        }

      } )

    }
  }

  private erroRequisicao ( mensagem: string ): void {
    this.getItems().finally( () => {

      MensagemStoreModule.exibirMensagem( {
        mensagem: mensagem,
        tipo: TipoMensagemType.erro,
        titulo: 'Erro'

      } )

    } )

  }

  // Daqui em diante....
  // Upload do video de Abertura

  private exibirProgresso: boolean = false

  private progressoUpload: number = 0

  private calculaprogressoUpload ( event: ProgressEvent ) {

    this.progressoUpload = Math.round( ( event.loaded * 100 ) / event.total )
    this.exibirProgresso = this.progressoUpload > 0

  }

  private midia: File | null = null

  private btUpload (): void {

    const url: string = ENDPOINT.concat( 'uploadAulaMidia' )

    if ( this.midia ) {

      this.status = StatusFormType.processando

      MensagemStoreModule.exibirMensagem( {
        mensagem: 'Upload, aguarde....',
        tipo: TipoMensagemType.aguarde,
        titulo: 'Processando'
      } )

      const dados: FormData = new FormData()

      dados.append( 'nomeArquivo', this.midia )
      dados.append( 'token', LoginStoreModule.token )
      dados.append( 'idAula', this.idAula.toString() )
      dados.append( 'tipo', this.tipo.toString() )

      this.tempoVideo().then( ( rsTamanho: number ) => {

        dados.append( 'tempoVideo', rsTamanho.toString() )

        // console.log( 'tempoVideo', rsTamanho.toString() )

        axios.post( url, dados, {
          headers: {
            'content-type': 'multipart/form-data'
          },
          onUploadProgress: this.calculaprogressoUpload
        } ).then( rs => {

          this.status = StatusFormType.editando

          if ( !rs.data.ok ) {
            MensagemStoreModule.exibirMensagem( {
              mensagem: rs.data.mensagem,
              tipo: TipoMensagemType.erro,
              titulo: 'Erro'
            } )
          } else {
            MensagemStoreModule.ocultarMensagem()
            this.midia = null
            this.getItems()
          }
        } )

      } )

    }
  }

  private tempoVideo (): Promise<number> {

    return new Promise( ( resolve ) => {

      try {
        const video = document.createElement( 'video' )
        video.preload = 'metadata'

        video.onloadedmetadata = function () {
          // console.log( 'duracao', video.duration )
          resolve( video.duration )
        }

        video.onerror = function () {
          // console.log( 'Erro....' )
          resolve( 0 )
        }

        video.src = window.URL.createObjectURL( <File>this.midia )

      } catch ( e ) {
        // console.log( 'Erro....', e.message )
        resolve( 0 )
      }

    } )

  }


}