import ClsApolloClient from "@/utils/ClsApolloClient"
import Vue from "vue"
import Component from "vue-class-component"

import InputTeste from '@/components/teste/InputTeste.vue'
import ShowVideo from '@/components/video/ShowVideo.vue'

import PlayDownload from './PlayDownload.vue'
import PlayExercicio from './PlayExercicio.vue'
import { rsPlayAulaExercicioInterface, rsPlayAulaInterface, rsPlayInterface } from "@/interfaces/backend/PlayInterfaces"
import { MensagemStoreModule, TipoMensagemType } from "@/store/modules/MensagemStore"
import { StatusStoreModule } from "@/store/modules/StatusStore"
import { AtualizarEstatisticaInterface } from "@/interfaces/backend/EstatisticaInterfaces"
import { LoginStoreModule } from "@/store/modules/LoginStore"
import { EMDESENVOLVIMENTO } from "@/config/backend/emDesenvolvimento"

const DEBUG_PLAY: boolean = EMDESENVOLVIMENTO && false

@Component( {
  components: {
    InputTeste,
    ShowVideo,
    PlayDownload,
    PlayExercicio
  }
} )

export default class PlayCls extends Vue {

  private indicesAtuaisPlay = {
    indexAssunto: -1,
    indexTrilha: -1,
    indexAula: -1
  }

  private linkImagemBanner: string = ""
  private keyRefresh: number = 1000

  private get LoginStoreModule (): typeof LoginStoreModule { return LoginStoreModule }

  private get StatusStoreModule (): typeof StatusStoreModule { return StatusStoreModule }

  private rsAtualizarEstatistica: Partial<AtualizarEstatisticaInterface> = {}

  private isVideo: boolean = true
  private isExercicio: boolean = false

  private painel: Array<number> = []

  private rsExercicios: Array<rsPlayAulaExercicioInterface> = []

  private rsPlay: Partial<rsPlayInterface> = {
    titulo: ''
  }

  private tmpConclusaoAtual: number = 0

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private rsAula: Partial<rsPlayAulaInterface> = {}

  private mounted () {
    this.getMeuCurso()
  }

  /*
  private tmpClsEstilo: ClsEstilo = new ClsEstilo()

  private get clsEstilo (): ClsEstilo {
    return this.tmpClsEstilo
  }
  private get AulaMidiaType (): typeof AulaMidiaType {
    return AulaMidiaType
  }
*/
  /**
   * Realiza o Play da Video aula e seta exercícios para iniciar no final do vídel
   * @param aula 
   * @param exercicios Exercícios para Play após a video aula
   * @param idMatricula 
   * @param idCurso 
   * @param idCursoAssunto 
   * @param idCursoAssuntoTrilha 
   */
  private btPlayVideoAula (
    aula: rsPlayAulaInterface,
    exercicios: Array<rsPlayAulaExercicioInterface>,
    idMatricula: number,
    idCurso: number,
    idCursoAssunto: number,
    idCursoAssuntoTrilha: number,
    indexAssunto: number,
    indexTrilha: number,
    indexAula: number
  ) {

    DEBUG_PLAY ? console.log( 'Dentro do btPlayVideoAula', aula.idAula ) : ""

    this.rsAtualizarEstatistica = {
      idMatricula: idMatricula,
      idCurso: idCurso,
      idUsuario: LoginStoreModule.idUsuario,
      idCursoAssunto: idCursoAssunto,
      idCursoAssuntoTrilha: idCursoAssuntoTrilha,
      idAula: aula.idAula
    }

    this.rsAula = JSON.parse( JSON.stringify( aula ) )

    this.isVideo = true
    this.isExercicio = false
    this.rsExercicios = exercicios

    this.indicesAtuaisPlay = {
      indexAssunto: indexAssunto,
      indexAula: indexAula,
      indexTrilha: indexTrilha
    }

    DEBUG_PLAY ? console.log( 'Indices Atuais Play...', this.indicesAtuaisPlay ) : ""

  }

  private finalizarVideoIniciarExercicio () {
    this.isExercicio = true
    this.isVideo = false
  }

  private btExercicio ( aula: rsPlayAulaInterface, exercicios: Array<rsPlayAulaExercicioInterface>, idMatricula: number, idAula: number, idCurso: number, idCursoAssunto: number, idCursoAssuntoTrilha: number ) {

    this.rsAtualizarEstatistica = {
      idMatricula: idMatricula,
      idCurso: idCurso,
      idUsuario: LoginStoreModule.idUsuario,
      idCursoAssunto: idCursoAssunto,
      idCursoAssuntoTrilha: idCursoAssuntoTrilha,
      idAula: idAula
    }

    this.rsAula = JSON.parse( JSON.stringify( aula ) )

    this.rsExercicios = exercicios
    this.isExercicio = true
    this.isVideo = false

  }

  private getDuracaoVideo ( segundos: number ): string {

    const retorno: string = new Date( segundos * 1000 ).toISOString()

    return ( segundos < 3600 ) ? retorno.substring( 14, 19 ) : retorno.substring( 11, 19 )

  }

  private getMeuCurso ( irProximaAula: boolean = false ) {

    const query: string = `
      getMeuCurso(
        idCurso: ${this.StatusStoreModule.idCurso}
      ) {
        idMatricula
        idCurso
        titulo
        totAproveitamento
        totConclusao
        ptsAcertoAproveitamento
        ptsConclusao
        pesquisaSatisfacao
        ultimoAcesso
        concluido
        assuntos {
          idCursoAssunto
          titulo
          descricao
          totConclusao
          totAproveitamento
          ordem
          ptsConclusao
          pesquisaSatisfacao
          ultimoAcesso
          concluido
          trilhas {
            idCursoAssuntoTrilha
            titulo
            descricao
            totConclusao
            totAproveitamento
            ordem
            ptsConclusao
            pesquisaSatisfacao
            ultimoAcesso
            concluido
            aulas {
              idMatricula
              idAula
              descricao
              tempoVideo
              titulo
              totConclusao
              totAproveitamento
              ordem
              ptsConclusao
              ptsAcertoAproveitamento
              pesquisaSatisfacao
              tempoVideoPrincipalAtual
              ultimoAcesso
              concluido
              videoPrincipal {
                idAulaMidia                
              }
              exercicios {
                idAulaExercicio
                idAula
                titulo
                propriedades
                ordem
                idMatricula
                idCurso
                idUsuario
                idCursoAssunto
                idCursoAssuntoTrilha
                resposta
                ptsAcertoAproveitamento
                respostaCorreta
                correcao
              }
              downloads {
                idAula
                idAulaMidia
                nomeArquivo
              }
            }
          }
        }
      }
    `

    this.clsApollo.query<rsPlayInterface>( this, query, 'getMeuCurso', 'Pequisando Curso...' ).then( rs => {

      if ( !rs ) {
        MensagemStoreModule.exibirMensagem( { mensagem: 'Você não possui matrícula!', titulo: 'Matrícula Inexistente!', tipo: TipoMensagemType.aviso } )
        this.$router.push( '/' )
      } else {

        this.rsPlay = rs
        this.tmpConclusaoAtual = rs.totConclusao > 0 ? rs.ptsConclusao / rs.totConclusao * 100 : 0

        DEBUG_PLAY ? console.log( JSON.stringify( this.rsPlay ) ) : ""

        if ( irProximaAula ) {

          this.calcularEIrParaProximaAula()

        } else {
          this.keyRefresh++
        }

      }


    } )

  }

  private calcularEIrParaProximaAula () {
    if ( this.rsPlay && this.rsPlay.assuntos && this.indicesAtuaisPlay.indexAula >= 0 ) {

      let temProximaAula: boolean = false
      let indiceProximaAula: number = this.indicesAtuaisPlay.indexAula
      let indiceProximoAssunto: number = this.indicesAtuaisPlay.indexAssunto
      let indiceProximaTrilha: number = this.indicesAtuaisPlay.indexTrilha

      DEBUG_PLAY ? console.log( 'Antes: ',
        'indiceProximaAula', indiceProximaAula,
        'indiceProximoAssunto', indiceProximoAssunto,
        'indiceProximaTrilha', indiceProximaTrilha
      ) : ""

      if ( this.rsPlay.assuntos[this.indicesAtuaisPlay.indexAssunto].trilhas[this.indicesAtuaisPlay.indexTrilha].aulas.length - 1 > indiceProximaAula ) {
        indiceProximaAula++
        temProximaAula = true
      } else if ( this.rsPlay.assuntos[this.indicesAtuaisPlay.indexAssunto].trilhas.length - 1 > indiceProximaTrilha ) {
        indiceProximaAula = 0
        indiceProximaTrilha++
        temProximaAula = true
      } else if ( this.rsPlay.assuntos.length - 1 > indiceProximoAssunto ) {
        indiceProximaAula = 0
        indiceProximaTrilha = 0
        indiceProximoAssunto++
        temProximaAula = true
      }

      // DEBUG_PLAY ? console.log( 'Painel Antes: ', this.painel ) : ""

      DEBUG_PLAY ? console.log( 'Depois: ',
        'indiceProximaAula', indiceProximaAula,
        'indiceProximoAssunto', indiceProximoAssunto,
        'indiceProximaTrilha', indiceProximaTrilha
      ) : ""


      if ( temProximaAula ) {
        const rsAula: rsPlayAulaInterface = this.rsPlay.assuntos[indiceProximoAssunto].trilhas[indiceProximaTrilha].aulas[indiceProximaAula]

        this.btPlayVideoAula(
          rsAula,
          rsAula.exercicios as Array<rsPlayAulaExercicioInterface>,
          this.rsPlay.idMatricula as number,
          this.rsPlay.idCurso as number,
          this.rsPlay.assuntos[indiceProximoAssunto].idCursoAssunto,
          this.rsPlay.assuntos[indiceProximoAssunto].trilhas[indiceProximaTrilha].idCursoAssuntoTrilha,
          indiceProximoAssunto,
          indiceProximaTrilha,
          indiceProximaAula
        )

        // this.painel = [indiceProximoAssunto, indiceProximaTrilha+1]
        // DEBUG_PLAY ? console.log( 'Painel Depois: ', this.painel ) : ""
        this.keyRefresh++
      }

    }

  }

  private get conclusaoAtual (): number {
    return this.tmpConclusaoAtual
  }

  private proximaAula () {

    this.getMeuCurso( true )

  }

  private atualizarEstatisticaVisualizacaoParaAluno ( tempoAtual: number ): void {

    DEBUG_PLAY ? console.log( 'TempoAtual: ', tempoAtual ) : ""

    if ( this.rsPlay && this.rsPlay.assuntos )
      this.rsPlay.assuntos[this.indicesAtuaisPlay.indexAssunto].trilhas[this.indicesAtuaisPlay.indexTrilha].aulas[this.indicesAtuaisPlay.indexAula].tempoVideoPrincipalAtual = tempoAtual
  }

  private calcularProgresso ( concluido: number, total: number ) {
    return ( total > 0 && concluido ) ? concluido / total * 100 : 0
  }

}