import { DTFORMAT } from '@/config/DateFormat'
import { DateTime } from 'luxon'

export interface FormatsInterface {
  ISO_TO_USUARIO_DATA ( qual: string ): string;
  USUARIO_DATA_TO_ISO ( qual: string ): string;
  ISO_TO_USUARIO_DATA_HORA ( qual: string ): string;
  NUMERO_TO_DECIMAL ( qual: number ): string;
}

export const getFormats: FormatsInterface = {
  ISO_TO_USUARIO_DATA: ( qual: string ): string => {
    return DateTime.fromISO( qual ).toFormat( DTFORMAT.USUARIO_DATA )
  },

  USUARIO_DATA_TO_ISO: ( qual: string ): string => {
    return DateTime.fromFormat( qual, DTFORMAT.USUARIO_DATA ).toISO()
  },

  ISO_TO_USUARIO_DATA_HORA: ( qual: string ): string => {
    return DateTime.fromISO( qual ).toFormat( DTFORMAT.USUARIO_DATETIME )
  },

  NUMERO_TO_DECIMAL: ( qual: number ): string => {
    return qual.toLocaleString( 'pt-BR', { useGrouping: true, minimumFractionDigits: 2 } )
  }
}
