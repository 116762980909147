

















import s from './InputTesteCls'
export default s
