import { Component, Vue } from 'vue-property-decorator'

import { clsUtils, validadoresFrontEnd } from 'zlib-utils'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { StatusFormType } from '@/utils/dataTypes'
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento'
import { getFormats } from '@/utils/FormatarDados'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'

import UsuarioGrupo from './UsuarioGrupo.vue'
import UsuarioPermissao from './UsuarioPermissao.vue'
import ResetUsuario from './ResetUsuario.vue'
import { UsuarioInterface } from '@/interfaces/backend/UsuarioInterfaces'

import { mask } from 'vue-the-mask'

@Component( {
  components: {
    UsuarioGrupo,
    UsuarioPermissao,
    ResetUsuario
  },
  directives: {
    mask
  }
} )
export default class UsuarioCls extends Vue {

  private pesquisa: string = ''

  private validadoresFrontEnd = validadoresFrontEnd
  private formValid = false

  private editedItem: Partial<UsuarioInterface> = this.resetModel()
  private tmpRegistro: Partial<UsuarioInterface> = this.resetModel()

  private resetModel (): Partial<UsuarioInterface> {
    return {
      idUsuario: 0,
      email: '',
      login: '',
      nome: '',
      whatsApp: '',
      autor: false,
      professor: false
    }
  }
  private status: StatusFormType = StatusFormType.inicial

  private tituloForm: string = ""

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private mounted () {
    this.getDetalhe()
  }

  private btCancelar (): void {
    this.status = StatusFormType.inicial
  }

  private btIncluir (): void {

    this.tituloForm = 'Incluir Usuário'

    this.status = StatusFormType.incluindo

    if ( EMDESENVOLVIMENTO ) {
      this.editedItem = {
        idUsuario: 0,
        email: 'zanatta@fleekcursos.com.br',
        login: 'Zanatta',
        nome: 'Zanatta',
        whatsApp: '37988118785',
        autor: false,
        professor: false
      }
    } else {
      this.editedItem = this.resetModel()
    }
  }

  private btConfirmar (): void {

    if ( this.status == StatusFormType.incluindo || this.status == StatusFormType.editando ) {

      if ( ( this.$refs.form as Vue & { validate: () => boolean } ).validate() ) {

        const mutation: string = `
          updateUsuario(dados: ${clsUtils.ConverterEmGql(
          {
            idUsuario: this.editedItem.idUsuario,
            email: this.editedItem.email,
            login: this.editedItem.login,
            nome: this.editedItem.nome,
            whatsApp: this.editedItem.whatsApp,
            professor: this.editedItem.professor,
            autor: this.editedItem.autor
          } )} 
          ) {
            ok
            mensagem
          }
        `

        this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'updateUsuario', 'Atualizando Usuário' ).then( rs => {
          if ( rs.ok ) {
            this.status = StatusFormType.inicial
            this.getDetalhe()
          } else {

            MensagemStoreModule.exibirMensagem( {
              mensagem: rs.mensagem,
              tipo: TipoMensagemType.erro,
              titulo: 'Erro!!!'
            } )

          }

        } )

      }
    } else if ( this.status == StatusFormType.excluindo ) {

      const mutation: string = `
        delUsuario(idUsuario: ${this.editedItem.idUsuario} ) {
          ok
          mensagem
        }
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'delUsuario', 'Excluindo Usuário....' ).then( rs => {
        if ( rs.ok ) {
          this.status = StatusFormType.inicial
          this.getDetalhe()
        } else {

          MensagemStoreModule.exibirMensagem( {
            mensagem: rs.mensagem,
            tipo: TipoMensagemType.erro,
            titulo: 'Erro!!!'
          } )

        }
      } )

    }
  }

  private cabecalhos = [
    { text: 'Nome', value: 'nome' },
    { text: 'e-mail', value: 'email' },
    { text: 'Login', value: 'login' },
    { text: 'Whatsapp', value: 'whatsApp' },
    { text: 'Ações', value: 'actions', sortable: false }
  ]

  private registros: Array<UsuarioInterface> = []

  private viewItem ( item: UsuarioInterface ) {
    this.tituloForm = "Visualizar Usuário"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.exibindo
  }

  private editItem ( item: UsuarioInterface ) {
    this.tituloForm = "Alterar Usuário"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.editando
  }

  private deleteItem ( item: UsuarioInterface ) {
    this.tituloForm = "Excluir Usuário"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.excluindo
  }

  private getDetalhe () {

    this.registros = []

    const query: string = `
      getUsuarios(pesquisa: "${this.pesquisa}") {
        idUsuario
        email
        login
        nome
        whatsApp
        professor
        autor
      }
    `

    this.clsApollo.query<Array<UsuarioInterface>>( this, query, 'getUsuarios', 'Pequisando Usuários...' ).then( rs => {
      this.registros = rs
    } )
  }

  private btPesquisar (): void {
    this.getDetalhe()
  }

  private get getFormats (): typeof getFormats {
    return getFormats
  }

  private refresh (): void {
    this.status = StatusFormType.inicial
    this.getDetalhe()
  }

}
