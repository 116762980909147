import { AtualizarEstatisticaInterface } from "@/interfaces/backend/EstatisticaInterfaces"
import { RespostaPadraoInterface } from "@/interfaces/backend/PadraoInterfaces"
import { rsPlayAulaInterface } from "@/interfaces/backend/PlayInterfaces"
import ClsApolloClient from "@/utils/ClsApolloClient"
import Vue from "vue"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { clsUtils } from "zlib-utils"

@Component
export default class PlayCls extends Vue {

  @Prop( { default: () => { return {} } } )
  private rsAula: rsPlayAulaInterface

  @Prop( { default: () => { return {} } } )
  private rsAtualizarEstatistica: AtualizarEstatisticaInterface

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private avaliacao: number = 0

  private btAvaliarEConcluir () {

    if ( !this.rsAula.concluido ) {

      const mutation: string = `
      concluirAula(
        rsAtualizarEstatistica: ${clsUtils.ConverterEmGql( this.rsAtualizarEstatistica )}, 
        avaliacao: ${this.avaliacao}        
        ) {
          ok
          mensagem
        }
        `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'concluirAula', 'Concluindo Aula....' ).then( rs => {
        if ( rs.ok ) {
          this.irProximaAula()
        }
      } )

    }

  }

  private irProximaAula () {

    /*
    // Retorna Número da Próxima Aula
    const query: string = `
      proximaAula(idMatricula: ${this.rsAtualizarEstatistica.idMatricula}, idCurso: ${this.rsAtualizarEstatistica.idCurso})
    `

    this.clsApollo.query<number>( this, query, 'proximaAula' ).then( rs => {
      console.log( 'Retorno Proxima Aula: ', rs )
      if ( rs ) {
        this.$router.push( '/play/'.concat( this.rsAtualizarEstatistica.idMatricula.toString() ).concat( '/' ).concat( this.rsAtualizarEstatistica.idCurso.toString() ).concat( '/' ).concat( rs.toString() ) )
      } else {
        this.$router.push( '/MeusCursos' )
      }
    } )
    */

    this.$emit( 'proximaAula' )

  }

  private get perConclusao (): number {
    let retorno: number = 0
    if ( this.rsAula.totConclusao > 0 && this.rsAula.ptsConclusao > 0 ) {
      retorno = this.rsAula.ptsConclusao / this.rsAula.totConclusao * 100
    }
    return retorno
  }

  private get perAproveitamento (): number {
    let retorno: number = 0
    if ( this.rsAula.totAproveitamento > 0 && this.rsAula.ptsAcertoAproveitamento > 0 ) {
      retorno = this.rsAula.ptsAcertoAproveitamento / this.rsAula.totAproveitamento * 100
    }
    return retorno
  }

}