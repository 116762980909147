




























import { Component, Vue } from 'vue-property-decorator'
import { MensagemStoreModule, StateMensagemInterface, TipoMensagemType } from '@/store/modules/MensagemStore'

@Component
export default class Mensagem extends Vue {

  private get MensagemStoreModule (): StateMensagemInterface { return MensagemStoreModule }

  private get TipoMensagemType (): typeof TipoMensagemType { return TipoMensagemType }

}
