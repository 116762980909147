import { Component, Vue } from 'vue-property-decorator'

import { clsUtils, validadoresFrontEnd } from 'zlib-utils'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { StatusFormType } from '@/utils/dataTypes'
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento'
import { AulaInterface } from '@/interfaces/backend/AulaInterfaces'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'

import AulaTag from './AulaTag.vue'
import AulaMidia from './AulaMidia.vue'
import Exercicio from '../aulaexercicio/Exercicio.vue'
import { AulaMidiaType } from '@/types/backend/AulaMidiaDataType'

@Component( {
  components: {
    AulaTag,
    AulaMidia,
    Exercicio
  }
} )
export default class AulaCls extends Vue {

  private pesquisa: string = ''

  private validadoresFrontEnd = validadoresFrontEnd
  private formValid = false

  private editedItem: AulaInterface = this.resetModel()
  private tmpRegistro: AulaInterface = this.resetModel()

  private resetModel (): AulaInterface {
    return {
      idAula: 0,
      descricao: '',
      titulo: '',
      degustacao: false
    }
  }
  private status: StatusFormType = StatusFormType.inicial

  private tituloForm: string = "Cadastro de Aula"

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private get AulaMidiaType (): typeof AulaMidiaType {
    return AulaMidiaType
  }

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private mounted () {
    this.getDetalhe()
  }

  private btCancelar (): void {
    this.status = StatusFormType.inicial
  }

  private btIncluir (): void {

    this.tituloForm = 'Incluir Aula'

    this.status = StatusFormType.incluindo

    if ( EMDESENVOLVIMENTO ) {
      this.editedItem = {
        idAula: 0,
        descricao: 'Aula Teste 01',
        titulo: 'Titulo Aula Teste 01',
        degustacao: false
      }
    } else {
      this.editedItem = this.resetModel()
    }
  }

  private btConfirmar (): void {

    if ( [StatusFormType.editando, StatusFormType.incluindo].includes( this.status ) ) {

      if ( ( this.$refs.form as Vue & { validate: () => boolean } ).validate() ) {

        const mutation: string = `
            updateAula(dados: ${clsUtils.ConverterEmGql( this.editedItem )} ) {
              ok
              mensagem
            }
          `

        this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'updateAula', 'Atualizando Aula' ).then( rs => {
          if ( rs.ok ) {
            this.status = StatusFormType.inicial
            this.getDetalhe()
          }
        } )

      }
    } else if ( this.status == StatusFormType.excluindo ) {

      const mutation: string = `
        delAula(idAula: ${this.editedItem.idAula} ) {
          ok
          mensagem
        }
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'delAula', 'Excluindo Aula....' ).then( rs => {
        if ( rs.ok ) {
          this.status = StatusFormType.inicial
          this.getDetalhe()
        } else {

          MensagemStoreModule.exibirMensagem( {
            mensagem: rs.mensagem,
            tipo: TipoMensagemType.erro,
            titulo: 'Erro!!!'
          } )

        }
      } )

    }
  }

  private cabecalhos = [
    { text: 'Título', value: 'titulo' },
    { text: 'Descrição', value: 'descricao' },
    { text: 'Ações', value: 'actions', sortable: false }
  ]

  private registros: Array<AulaInterface> = []

  private viewItem ( item: AulaInterface ) {
    this.tituloForm = "Visualizar Aula"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.exibindo
  }

  private editItem ( item: AulaInterface ) {
    this.tituloForm = "Alterar Aula"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.editando
  }

  private deleteItem ( item: AulaInterface ) {
    this.tituloForm = "Excluir Aula"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.excluindo
  }

  private getDetalhe () {

    const query: string = `
      getAulas(pesquisa: "${this.pesquisa}") {
        idAula
        titulo
        descricao
        degustacao
      }
    `

    this.clsApollo.query<Array<AulaInterface>>( this, query, 'getAulas', 'Pequisando Aulas...' ).then( rs => {
      this.registros = rs
    } ).catch( () => {
      this.registros = []
    } )
  }

  private btPesquisar (): void {
    this.getDetalhe()
  }

  private isEditandoExercicio: boolean = false

  private iniciarEdicaoExercicio (): void {
    this.isEditandoExercicio = true
  }

  private concluirEdicaoExercicio (): void {
    this.isEditandoExercicio = false
  }

}
