import _ from 'lodash'
import gql from 'graphql-tag'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'
import ClsHandleErroAPP from './handleErroApp'
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento'

const DEPURAR_REQUISICOES: boolean = EMDESENVOLVIMENTO ? false : false
const DEPURAR_RETORNO: boolean = EMDESENVOLVIMENTO ? false : false

export default class ClsApolloClient {

  public query<T> ( self: any, query: string, nomeObjRetorno: string, aguarde?: string ): Promise<T> {

    if ( aguarde ) {
      MensagemStoreModule.exibirMensagem( {
        mensagem: aguarde,
        tipo: TipoMensagemType.aguarde,
        titulo: 'Aguarde'
      } )
    }

    if ( DEPURAR_REQUISICOES ) {
      console.log( 'Query: ', query )
    }

    return new Promise( ( resolve ) => {

      return self.$apollo.query( {

        query: gql( `query { ${query} }` ),
        fetchPolicy: "no-cache"

      } ).then( ( rs: any ) => {

        if ( !rs || rs.errors || rs.error ) {
          MensagemStoreModule.exibirMensagem( {
            mensagem: 'Erro no Servidor. Consulte Suporte.',
            tipo: TipoMensagemType.erro,
            titulo: 'ERRO!!!'
          } )

          resolve( <any>'Erro...' )

        } else {

          const objeto: any = _.find( rs, nomeObjRetorno ) || {}

          if ( aguarde ) {
            MensagemStoreModule.ocultarMensagem()
          }

          if ( DEPURAR_RETORNO ) {
            console.log( 'Obj Retorno Query: ', JSON.stringify( objeto[nomeObjRetorno] ) )
          }

          resolve( objeto[nomeObjRetorno] )

        }

      } ).catch( ( err: Error ) => {

        MensagemStoreModule.exibirMensagem( {
          mensagem: 'Erro no Servidor. Consulte Suporte.',
          tipo: TipoMensagemType.erro,
          titulo: 'ERRO!!!'
        } )

        ClsHandleErroAPP.handle( err, 'ClsApolloClient', 'Query' )

        resolve( <any>'Erro...' )
        /*
        if ( mensagemAlerta ) {
          mensagemAlerta.tipo = TipoAlertaMensagemType.erro
          mensagemAlerta.mensagem = 'Erro no Servidor. Consulte Suporte.'
          mensagemAlerta.exibir = true
          mensagemAlerta.titulo = 'ERRO!!!'
          console.log( 'ERRO: ', err )
        } else {
          reject( err.message || 'Erro...' )
        }
        */
      } )

    } )

  }

  public mutation<T> ( self: any, mutation: string, nomeObjRetorno: string, aguarde?: string ): Promise<T> {
    /*
        if ( mensagemAlerta && mensagem ) {
          mensagemAlerta.mensagem = mensagem
          mensagemAlerta.exibir = true
        }
    */

    if ( aguarde ) {
      MensagemStoreModule.exibirMensagem( {
        mensagem: aguarde,
        tipo: TipoMensagemType.aguarde,
        titulo: 'Aguarde'
      } )
    }

    if ( DEPURAR_REQUISICOES ) {
      console.log( 'Mutation: ', mutation )
    }

    return new Promise<T>( ( resolve ) => {

      return self.$apollo.mutate( {

        mutation: gql( `mutation { ${mutation} }` )

      } ).then( ( rs: { error: string, errors: string } ) => {

        if ( !rs || rs.errors || rs.error ) {

          MensagemStoreModule.exibirMensagem( {
            mensagem: 'Erro no Servidor. Consulte Suporte.',
            tipo: TipoMensagemType.erro,
            titulo: 'ERRO!!!'
          } )

          resolve( <any>'Erro...' )

        } else {

          const objeto: any = _.find( rs, nomeObjRetorno ) || {}

          if ( aguarde ) {
            MensagemStoreModule.ocultarMensagem()
          }

          // Retornar o nomeObjRetorno

          if ( DEPURAR_RETORNO ) {
            console.log( 'Obj Retorno Mutation: ', JSON.stringify( objeto[nomeObjRetorno] ) )
          }

          resolve( objeto[nomeObjRetorno] )

        }
      } ).catch( ( err: Error ) => {

        MensagemStoreModule.exibirMensagem( {
          mensagem: 'Erro no Servidor. Consulte Suporte.',
          tipo: TipoMensagemType.erro,
          titulo: 'ERRO!!!'
        } )

        ClsHandleErroAPP.handle( err, 'ClsApolloClient', 'Mutation' )

        resolve( <any>'Erro...' )

      } )

    } )

  }

}