import { ENDPOINT } from "@/config/backend/emDesenvolvimento"
import { AtualizarEstatisticaInterface } from "@/interfaces/backend/EstatisticaInterfaces"
import { RespostaPadraoInterface } from "@/interfaces/backend/PadraoInterfaces"
import { rsPlayAulaInterface } from "@/interfaces/backend/PlayInterfaces"
import { LoginStoreModule } from "@/store/modules/LoginStore"
import ClsApolloClient from "@/utils/ClsApolloClient"
import ClsEstilo from "@/utils/ClsEstilo"
import axios from "axios"
import Vue from "vue"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import { clsUtils } from "zlib-utils"

const DEBUG_SHOW_VIDEO: boolean = false

interface videoInterface extends HTMLElement {
  currentTime?: number
  paused: boolean
  ended: boolean
  readyState: number
  HAVE_CURRENT_DATA: number
  autoplay: boolean
  pause (): void
  play (): void
}

@Component
export default class showVideoCls extends Vue {

  private linkMidia: string = ""
  private keyRefresh: number = 1

  private contadorAtualizacaoTempoVideo: number = 0

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  @Prop()
  private rsAula: rsPlayAulaInterface

  @Prop()
  private rsAtualizarEstatistica: AtualizarEstatisticaInterface

  @Prop( { default: false } )
  private isVideo: boolean

  @Watch( 'isVideo' )
  private onChangeIsVideo () {

    const video: videoInterface = document.getElementById( 'idShowVideo' ) as videoInterface

    if ( !this.isVideo && !video.paused ) {
      video.pause()
    } else if ( this.isVideo ) {
      video.play()
    }

  }

  private idAulaMidia: number = 0

  private tempoAtual: number = 0

  @Watch( 'rsAula' )
  private onChangeRsAula () {

    if ( this.rsAula && this.rsAula.videoPrincipal && this.rsAula.videoPrincipal.idAulaMidia ) {

      this.refreshMidia()

      DEBUG_SHOW_VIDEO ? console.log( 'Dentro do onChangeRsAula, calculando tempos....' ) : ""

      const video: videoInterface = document.getElementById( 'idShowVideo' ) as videoInterface

      video.autoplay = false

      this.tempoAtual = this.rsAula.tempoVideoPrincipalAtual

      this.idAulaMidia = this.rsAula.videoPrincipal.idAulaMidia

      if ( this.rsAula.tempoVideoPrincipalAtual >= this.rsAula.tempoVideo ) {
        video.currentTime = 0.001
      } else {
        video.currentTime = this.rsAula.tempoVideoPrincipalAtual
      }

      DEBUG_SHOW_VIDEO ? console.log( 'Dentro do onChangeRsAula - Tempo Setado: ', video.currentTime ) : ""

      const isPlaying: boolean = video.currentTime > 0 && !video.paused && !video.ended
        && video.readyState > video.HAVE_CURRENT_DATA;

      if ( !isPlaying && this.isVideo ) {
        video.autoplay = true
      }

    }

  }

  private mounted () {

    // TODO - PAREI AQUI - ESTATISTICAS...

    const video: videoInterface = document.getElementById( 'idShowVideo' ) as videoInterface

    // this.onChangeRsAula()

    if ( video ) {

      video.addEventListener( 'ended', () => {
        this.updateVideoTime( video.currentTime ?? 0, true )
      } );
      /*
            video.addEventListener( 'progress', () => {
              if ( video.currentTime && video.currentTime > this.tempoAtual )
                this.updateVideoTime( video.currentTime )
            } );
      */
      video.addEventListener( 'timeupdate', () => {

        if ( ++this.contadorAtualizacaoTempoVideo > 100 ) {

          if ( video.currentTime
            && video.currentTime > this.tempoAtual ) {

            this.updateVideoTime( video.currentTime )
          }

          this.contadorAtualizacaoTempoVideo = 0

        }

      } )

    }

  }

  // Tipos e Constantes

  private tmpClsEstilo: ClsEstilo = new ClsEstilo()

  private get clsEstilo (): ClsEstilo {
    return this.tmpClsEstilo
  }

  // Atualização de Estatísticas - Somente 1 requisição por vez.
  private isUpdating: boolean = false

  private updateVideoTime ( tempoAtualVideo: number, ended: boolean = false ) {

    DEBUG_SHOW_VIDEO ? console.log( 'Dentro do UpdateTimeVideo', this.rsAula.concluido, this.isUpdating ) : ""

    if ( !this.rsAula.concluido && !this.isUpdating ) {

      const mutation: string = `
        updateTempoVideoAssistido(
          rsAtualizarEstatistica: ${clsUtils.ConverterEmGql( this.rsAtualizarEstatistica )}, 
          tempoAtualVideo: ${tempoAtualVideo}        
        ) {
          ok
          mensagem
        }
      `

      this.isUpdating = true

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'updateTempoVideoAssistido' ).then( rs => {
        if ( rs.ok ) {
          this.tempoAtual = tempoAtualVideo
          this.isUpdating = false

          this.$emit( 'onUpdateVideoTime', this.tempoAtual )

          if ( ended ) {
            this.$emit( 'onVideoConcluido' )
          }

        }
      } )

    }

  }

  // private tipoMidia: string = ""

  private refreshMidia (): void {

    const idAulaMidia: number = this.rsAula && this.rsAula.videoPrincipal && this.rsAula.videoPrincipal.idAulaMidia ? this.rsAula.videoPrincipal.idAulaMidia : 0

    if ( idAulaMidia ) {
      DEBUG_SHOW_VIDEO ? console.log( 'Dentro do Refresh Midia....' ) : ""

      const urlVideo: string = ENDPOINT.concat( 'playAulaMidia/'.concat( idAulaMidia.toString() ).concat( '/' ).concat( LoginStoreModule.token ) )

      axios.get( urlVideo, {

        responseType: 'json',

      } ).then( rs => {

        this.linkMidia = rs.data.link
        this.keyRefresh++

      } )

    }

  }


}