















































































































































































































































import s from './MatriculaCls'
export default s
