import { Component, Vue } from 'vue-property-decorator'

import { StatusFormType } from '@/utils/dataTypes'

import ClsApolloClient from '../../../utils/ClsApolloClient'
import { LoginStoreModule } from '@/store/modules/LoginStore'
import { UsuarioLogadoInterface } from '@/interfaces/backend/LoginInterfaces'

@Component
export default class app extends Vue {

  public mounted (): void {
    this.logout()
  }

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private status: StatusFormType = StatusFormType.inicial

  private logout (): void {

    const clsApolloClient: ClsApolloClient = new ClsApolloClient()
    const logout = `
        logout {
          ok
          mensagem
        }
      `

    this.status = StatusFormType.processando

    clsApolloClient.mutation<UsuarioLogadoInterface>( this, logout, 'logout', 'Saindo da Aplicação....' ).then( rs => {

      if ( rs.ok ) {
        LoginStoreModule.logout()
        this.$router.push( '/Login' )

      } else {

        this.status = StatusFormType.inicial

      }

    } ).catch( err => {
      console.log( 'ERRO....', err )
    } )

  }

}