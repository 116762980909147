import { ExercicioCompletarInterface } from "@/types/backend/ExerciciosDataType";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { validadoresFrontEnd } from "zlib-utils";

@Component
export default class CadastroCompletarCls extends Vue {

  private get validadoresFrontEnd (): typeof validadoresFrontEnd {
    return validadoresFrontEnd
  }

  private txtTexto: string = ''

  private formValid: boolean = false

  @Prop()
  private value: Array<ExercicioCompletarInterface>

  private edicao: Array<ExercicioCompletarInterface> = []

  private mounted () {
    this.onChange()
  }

  @Watch( 'value' )
  private onChange () {
    this.edicao = JSON.parse( JSON.stringify( this.value ) )
  }

  private deleteItem ( indice: number ) {

    this.txtTexto = this.edicao[indice].texto
    this.edicao.splice( indice, 1 )
    this.update()

  }

  private addItem () {

    this.edicao.push( { texto: this.txtTexto } )
    this.txtTexto = ""
    this.update()

  }

  private update (): void {
    this.$emit( 'input', this.edicao )
  }

  // Drag And Drop

  private startDrag ( evt: DragEvent, indice: string ) {

    if ( evt.dataTransfer ) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData( 'indiceDrag', indice )
    }

  }

  private onDrop ( evt: DragEvent, indiceDrop: string ) {

    if ( evt.dataTransfer ) {

      const tmpItem: ExercicioCompletarInterface = JSON.parse( JSON.stringify( this.edicao[parseInt( indiceDrop )] ) )
      const indiceDrag = evt.dataTransfer.getData( 'indiceDrag' )

      this.edicao[parseInt( indiceDrop )] = this.edicao[parseInt( indiceDrag )]
      this.edicao[parseInt( indiceDrag )] = tmpItem

      this.update()

    }
  }

}