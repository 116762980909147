


























import s from './InputCompletarLinhaCls'
export default s
