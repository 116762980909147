










































import Vue from 'vue'
import Component from 'vue-class-component'

/*
import StoreModule from '@/store'
import { NotificacaoInterface } from '@/interfaces/backend/NotificacaoInterface'

interface MensagensNotificacaoInterface {
  titulo: string;
  descricao: string;
  icone: string;
  link: string;
  exibir: boolean;
}
*/

@Component
export default class Notificacao extends Vue {
  /*
    private get logado (): boolean {
      return StoreModule.state.login.logado
    }
  
    private get notificacao (): NotificacaoInterface {
      return StoreModule.state.notificacao
    }
  
    private irPara ( onde: string ): void {
      this.$router.push( onde )
    }
  
    private get notificacoes (): Array<MensagensNotificacaoInterface> {
  
      const retorno: Array<MensagensNotificacaoInterface> = []
  
      this.notificacao.novosLeads.forEach( ( nome ) => {
        retorno.push( {
          titulo: 'Novo Lead',
          descricao: nome,
          link: '/Leads',
          icone: 'mdi-account-details',
          exibir: true
        } )
      } )
  
      return retorno
    }
  */
}

