














































































import s from './GrupoPermissaoCls'
export default s
