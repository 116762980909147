import { LoginStoreModule } from "@/store/modules/LoginStore";

export default class ClsAcesso {
  public chkAcesso ( modulo: string | undefined, permissao: string | undefined ): boolean {

    // console.log( 'Permissão de Módulo: ', modulo, 'Permissão: ', permissao )

    if ( modulo && permissao ) {
      const retorno: boolean = LoginStoreModule.permissoes.some( x => x.modulo == modulo && x.permissao == permissao )
      return retorno
      // return LoginStoreModule.permissoes.some( x => x.modulo == modulo && x.permissao == permissao )
    } else {
      // console.log( 'Return True Constante' )
      return true
    }
  }
}