import Vue from "vue"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"

import { ExercicioCompletarInterface } from "@/types/backend/ExerciciosDataType"

import InputCompletarLinha from './InputCompletarLinha.vue'

@Component( {
  components: {
    InputCompletarLinha
  }
} )
export default class InputCompletarCls extends Vue {

  @Prop( {
    default: () => []
  } )
  private respostas: Array<Array<string>>

  @Prop( {
    default: () => []
  } )
  private value: Array<ExercicioCompletarInterface>

  @Prop( { default: 0 } )
  private refresh: number

  @Watch( 'refresh' )
  private onChangeValue () {
    this.rsRespostasEditadas = []

    this.value.forEach( ( exercicio: ExercicioCompletarInterface, index: number ) => {
      this.rsRespostasEditadas.push( this.respostas.length > index ? this.respostas[index] : [] )
    } )

    this.keyRefresh++
  }

  @Prop( { default: false } )
  private aulaConcluida: boolean

  private emEdicao: boolean = false
  private rsRespostasEditadas: Array<Array<string>> = []
  private ptsAcerto: number = 0
  private respostaCorreta: boolean = false

  private mounted () {
    this.rsRespostasEditadas = []
    this.value.forEach( ( exercicio: ExercicioCompletarInterface, index: number ) => {
      this.rsRespostasEditadas.push( this.respostas.length > index ? this.respostas[index] : [] )
    } )
  }

  private onChangeResposta ( chave: number, rsResposta: Array<string>, ptsAcerto: number, respostaCorreta: boolean ) {
    this.rsRespostasEditadas[chave] = rsResposta
    this.ptsAcerto = ptsAcerto
    this.emEdicao = true
    this.respostaCorreta = respostaCorreta
  }

  private btCancelar () {
    this.emEdicao = false
  }

  private btConfirmar () {
    this.$emit( 'onChangeResposta', this.rsRespostasEditadas, this.ptsAcerto, this.respostaCorreta )
    this.emEdicao = false
  }

  private keyRefresh: number = 0

}