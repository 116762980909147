export enum StatusFormType {
  inicial = 0,
  valido = 1,
  erro = 2,
  processando = 3,
  editando = 4,
  incluindo = 5,
  excluindo = 6,
  exibindo = 7
}

/*
interface getRolesInterface {
  status ( idStatus: number ): StatusRole;
  canal ( idCanal: number ): CanalRole;
  tipo ( idTipo: number ): TipoLeadRole;
  usuario ( idUsuario: number ): UsuarioCadastroInterface;
}
*/

export const getRoles: any = {

  /*
  usuario: ( idUsuario: number ) => {
    return _.find( DataStoreModule.usuarios, { idUsuario: idUsuario } )
  }
  */

}

