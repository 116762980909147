import { Component, Vue } from 'vue-property-decorator'

import { clsUtils, validadoresFrontEnd } from 'zlib-utils'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { StatusFormType } from '@/utils/dataTypes'
import { EMDESENVOLVIMENTO, ENDPOINT } from '@/config/backend/emDesenvolvimento'
import { CursoInterface } from '@/interfaces/backend/CursoInterfaces'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'
import CursoAssunto from './CursoAssunto.vue'
import { LoginStoreModule } from '@/store/modules/LoginStore'
import axios from 'axios'
import ClsEstilo from '@/utils/ClsEstilo'
import AutoCompleteCad from '@/components/autoCompleteCad.vue'

@Component( {
  components: {
    CursoAssunto,
    AutoCompleteCad
  }
} )
export default class CursoCls extends Vue {

  private pesquisa: string = ''

  private validadoresFrontEnd = validadoresFrontEnd
  private formValid = false

  private editedItem: Partial<CursoInterface> = this.resetModel()
  private tmpRegistro: Partial<CursoInterface> = this.resetModel()

  private refreshDetalhe: number = 1

  private resetModel (): Partial<CursoInterface> {
    return {
      idCurso: 0,
      descricao: '',
      titulo: ''
    }
  }
  private status: StatusFormType = StatusFormType.inicial

  private tituloForm: string = "Cadastro de Curso"

  private registros: Array<CursoInterface> = []

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private mounted () {
    this.getDetalhe()
  }

  private btCancelar (): void {
    this.status = StatusFormType.inicial
  }

  private btIncluir (): void {

    this.tituloForm = 'Incluir Curso'

    this.status = StatusFormType.editando

    if ( EMDESENVOLVIMENTO ) {
      this.editedItem = {
        idCurso: 0,
        descricao: 'Curso Teste 01',
        titulo: 'Titulo Curso Teste 01'
      }
    } else {
      this.editedItem = this.resetModel()
    }
  }

  private btConfirmar (): void {

    if ( this.status == StatusFormType.editando ) {

      if ( ( this.$refs.form as Vue & { validate: () => boolean } ).validate() ) {

        /*
        if ( this.editedItem.idAutor == null ) {
          delete this.editedItem.idAutor
        }

        if ( this.editedItem.idProfessor == null ) {
          delete this.editedItem.idProfessor
        }
        */

        const mutation: string = `
            updateCurso(dados: ${clsUtils.ConverterEmGql( this.editedItem )} ) {
              ok
              mensagem
            }
          `

        this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'updateCurso', 'Atualizando Curso' ).then( rs => {
          if ( rs.ok ) {
            this.status = StatusFormType.inicial
            this.getDetalhe()
          }
        } )

      }
    } else if ( this.status == StatusFormType.excluindo ) {

      const mutation: string = `
        delCurso(idCurso: ${this.editedItem.idCurso} ) {
          ok
          mensagem
        }
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'delCurso', 'Excluindo Curso....' ).then( rs => {
        if ( rs.ok ) {
          this.status = StatusFormType.inicial
          this.getDetalhe()
        } else {

          MensagemStoreModule.exibirMensagem( {
            mensagem: rs.mensagem,
            tipo: TipoMensagemType.erro,
            titulo: 'Erro!!!'
          } )

        }
      } )

    }
  }

  private cabecalhos = [
    { text: 'Título', value: 'titulo' },
    { text: 'Descrição', value: 'descricao' },
    { text: 'Ações', value: 'actions', sortable: false }
  ]

  private viewItem ( item: CursoInterface ) {
    this.tituloForm = "Visualizar Curso"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.exibindo
    this.getImagensCurso()
    this.refreshDetalhe++
  }

  private editItem ( item: CursoInterface ) {
    this.tituloForm = "Alterar Curso"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.editando
    this.getImagensCurso()
    this.refreshDetalhe++
  }

  private deleteItem ( item: CursoInterface ) {
    this.tituloForm = "Excluir Curso"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.excluindo
    this.getImagensCurso()
    this.refreshDetalhe++
  }

  private getDetalhe () {

    const query: string = `
      getCursos(pesquisa: "${this.pesquisa}") {
        idCurso
        titulo
        descricao
        idProfessor
        idAutor
      }
    `

    this.clsApollo.query<Array<CursoInterface>>( this, query, 'getCursos', 'Pequisando Cursos...' ).then( rs => {
      this.registros = rs
    } ).catch( () => {
      this.registros = []
    } )
  }

  private btPesquisar (): void {
    this.getDetalhe()
  }

  // Upload de Imagem de Curso

  private midiaIcone: File | null = null
  private midiaBanner: File | null = null
  private exibirProgresso: boolean = true
  private keyRefreshImage: number = 0
  private linkImagemBanner: string = ""
  private linkImagemIcone: string = ""

  private progressoUpload: number = 0

  private calculaprogressoUpload ( event: ProgressEvent ) {

    this.progressoUpload = Math.round( ( event.loaded * 100 ) / event.total )
    this.exibirProgresso = this.progressoUpload > 0

  }

  private btUpload ( tipo: string ): void {

    const url: string = ENDPOINT.concat( 'uploadCurso/'.concat( tipo ) )

    if ( ( ( tipo == 'banner' && this.midiaBanner ) || ( tipo == 'icone' && this.midiaIcone ) ) && this.editedItem.idCurso ) {

      this.status = StatusFormType.processando

      MensagemStoreModule.exibirMensagem( {
        mensagem: 'Upload, aguarde....',
        tipo: TipoMensagemType.aguarde,
        titulo: 'Processando'
      } )

      const dados: FormData = new FormData()

      if ( tipo == 'banner' && this.midiaBanner )
        dados.append( 'nomeArquivo', this.midiaBanner )

      if ( tipo == 'icone' && this.midiaIcone )
        dados.append( 'nomeArquivo', this.midiaIcone )

      dados.append( 'token', LoginStoreModule.token )
      dados.append( 'idCurso', this.editedItem.idCurso.toString() )

      axios.post( url, dados, {
        headers: {
          'content-type': 'multipart/form-data'
        },
        onUploadProgress: this.calculaprogressoUpload
      } ).then( rs => {

        this.status = StatusFormType.editando

        if ( !rs.data.ok ) {
          MensagemStoreModule.exibirMensagem( {
            mensagem: rs.data.mensagem,
            tipo: TipoMensagemType.erro,
            titulo: 'Erro'
          } )
        } else {
          MensagemStoreModule.ocultarMensagem()
          this.midiaIcone = null
          this.midiaBanner = null
          this.keyRefreshImage++
        }
      } )

    }
  }

  private tmpClsEstilo: ClsEstilo = new ClsEstilo()

  private get clsEstilo (): ClsEstilo {
    return this.tmpClsEstilo
  }

  private btFileInputBanner () {
    document.getElementById( 'fileInputBanner' )?.click()
  }

  private btFileInputIcone () {
    document.getElementById( 'fileInputIcone' )?.click()
  }

  private getImagensCurso (): void {

    const idCurso: number = this.tmpRegistro.idCurso ? this.tmpRegistro.idCurso : 0

    if ( LoginStoreModule.token && idCurso ) {

      const urlBanner: string = ENDPOINT.concat( 'downloadCurso/'.concat( idCurso.toString() ).concat( '/banner/' ).concat( LoginStoreModule.token ) )
      const urlIcone: string = ENDPOINT.concat( 'downloadCurso/'.concat( idCurso.toString() ).concat( '/icone/' ).concat( LoginStoreModule.token ) )

      axios.get( urlBanner, {

        responseType: 'json',

      } ).then( rs => {

        this.linkImagemBanner = rs.data.link

        axios.get( urlIcone, {

          responseType: 'json',

        } ).then( rs => {

          this.linkImagemIcone = rs.data.link
          this.keyRefreshImage++

        } )

      } )

    }

  }

}
