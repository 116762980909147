import { ExercicioTesteInterface } from "@/types/backend/ExerciciosDataType";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { validadoresFrontEnd } from "zlib-utils";

@Component
export default class inputTesteCls extends Vue {

  private get validadoresFrontEnd (): typeof validadoresFrontEnd {
    return validadoresFrontEnd
  }

  private txtResposta: string = ''

  private txtPontosTeste: string = ''

  private formValid: boolean = false

  @Prop()
  private value: ExercicioTesteInterface

  private edicao: ExercicioTesteInterface = { opcoes: [], opcoesCorretas: [], ptsTeste: 0 }

  private mounted () {
    this.onChange()
  }

  @Watch( 'value' )
  private onChange () {
    this.edicao = JSON.parse( JSON.stringify( this.value ) )
    this.txtPontosTeste = this.edicao.ptsTeste.toString()
  }

  private deleteItem ( indice: number ) {

    this.txtResposta = this.edicao.opcoes[indice]
    this.edicao.opcoes.splice( indice, 1 )

    this.edicao.opcoesCorretas = this.edicao.opcoesCorretas.filter( ( v, _i ) => {
      return v < this.edicao.opcoes.length
    } )

    this.update()


  }

  private addItem ( resposta: string ) {

    this.edicao.opcoes.push( resposta )
    this.txtResposta = ""
    this.update()

  }

  private update (): void {
    if ( ( this.$refs.form as Vue & { validate: () => boolean } ).validate() ) {
      this.edicao.ptsTeste = parseInt( this.txtPontosTeste )
      this.$emit( 'input', this.edicao )
    }
  }

  // Drag And Drop

  private startDrag ( evt: DragEvent, indice: string ) {

    if ( evt.dataTransfer ) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData( 'indiceDrag', indice )
    }

  }

  private onDrop ( evt: DragEvent, indiceDrop: string ) {

    if ( evt.dataTransfer ) {

      const tmpItem: string = this.edicao.opcoes[parseInt( indiceDrop )]
      const indiceDrag = evt.dataTransfer.getData( 'indiceDrag' )

      this.edicao.opcoes[parseInt( indiceDrop )] = this.edicao.opcoes[parseInt( indiceDrag )]
      this.edicao.opcoes[parseInt( indiceDrag )] = tmpItem

      this.update()

    }
  }

}