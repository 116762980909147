import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '..'

interface MensagemInterface {
  titulo: string;
  mensagem: string;
  tipo: TipoMensagemType;
  exibir: boolean;
}

interface inputMensagemInterface {
  titulo: string;
  mensagem: string;
  tipo: TipoMensagemType;
}

export enum TipoMensagemType {
  erro = 1,
  aviso = 2,
  aguarde = 3
}

export interface StateMensagemInterface extends MensagemInterface {
  exibirMensagem ( inputMensagem: inputMensagemInterface ): void;
  ocultarMensagem (): void;
}

// @Module( { namespaced: true, name: 'login' } )
@Module( { dynamic: true, store, name: 'mensagem' } )
class MensagemStore extends VuexModule implements StateMensagemInterface {

  titulo = ''
  mensagem = ''
  tipo: TipoMensagemType = TipoMensagemType.aviso
  exibir = false

  @Mutation
  show ( objMensagem: { mensagem: string; titulo: string; tipo: TipoMensagemType; exibir: boolean } ): void {
    this.mensagem = objMensagem.mensagem
    this.titulo = objMensagem.titulo
    this.tipo = objMensagem.tipo
    this.exibir = objMensagem.exibir
  }

  @Action( { commit: 'show' } )
  exibirMensagem ( inputMensagem: inputMensagemInterface ): inputMensagemInterface | { exibir: boolean } {
    return {
      mensagem: inputMensagem.mensagem,
      titulo: inputMensagem.titulo,
      tipo: inputMensagem.tipo ? inputMensagem.tipo : TipoMensagemType.aviso,
      exibir: true
    }
  }

  @Action( { commit: 'show' } )
  ocultarMensagem (): inputMensagemInterface | { exibir: boolean } {
    return {
      mensagem: '',
      titulo: '',
      tipo: TipoMensagemType.aviso,
      exibir: false
    }
  }

}

export const MensagemStoreModule: StateMensagemInterface = getModule( MensagemStore, store )