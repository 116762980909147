import { Component, Vue } from 'vue-property-decorator'

import { clsUtils, validadoresFrontEnd } from 'zlib-utils'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { StatusFormType } from '@/utils/dataTypes'
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento'
import { GrupoInterface } from '@/interfaces/backend/GrupoInterfaces'
import { getFormats } from '@/utils/FormatarDados'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'

import GrupoUsuario from './GrupoUsuario.vue'
import GrupoPermissao from './GrupoPermissao.vue'

@Component( {
  components: {
    GrupoUsuario,
    GrupoPermissao
  }
} )
export default class GrupoCls extends Vue {

  private pesquisa: string = ''

  private validadoresFrontEnd = validadoresFrontEnd
  private formValid = false

  private editedItem: GrupoInterface = this.resetModel()
  private tmpRegistro: GrupoInterface = this.resetModel()

  private resetModel (): GrupoInterface {
    return {
      idGrupo: 0,
      descricao: ''
    }
  }
  private status: StatusFormType = StatusFormType.inicial

  private tituloForm: string = ""

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private mounted () {
    this.getDetalhe()
  }

  private btCancelar (): void {
    this.status = StatusFormType.inicial
  }

  private btIncluir (): void {

    this.tituloForm = 'Incluir Grupo'

    this.status = StatusFormType.editando

    if ( EMDESENVOLVIMENTO ) {
      this.editedItem = {
        idGrupo: 0,
        descricao: 'Grupo'
      }
    } else {
      this.editedItem = this.resetModel()
    }
  }

  private btConfirmar (): void {

    if ( this.status == StatusFormType.editando ) {

      if ( ( this.$refs.form as Vue & { validate: () => boolean } ).validate() ) {

        const mutation: string = `
            updateGrupo(dados: ${clsUtils.ConverterEmGql( this.editedItem )} ) {
              ok
              mensagem
            }
          `

        this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'updateGrupo', 'Atualizando Grupo' ).then( rs => {
          if ( rs.ok ) {
            this.status = StatusFormType.inicial
            this.getDetalhe()
          }
        } )

      }
    } else if ( this.status == StatusFormType.excluindo ) {

      const mutation: string = `
        delGrupo(idGrupo: ${this.editedItem.idGrupo} ) {
          ok
          mensagem
        }
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'delGrupo', 'Excluindo Grupo....' ).then( rs => {
        if ( rs.ok ) {
          this.status = StatusFormType.inicial
          this.getDetalhe()
        } else {

          MensagemStoreModule.exibirMensagem( {
            mensagem: rs.mensagem,
            tipo: TipoMensagemType.erro,
            titulo: 'Erro!!!'
          } )

        }
      } )

    }
  }

  private cabecalhos = [
    // { text: 'idCliente', value: 'idCliente' },
    { text: 'Descrição', value: 'descricao' },
    { text: 'Ações', value: 'actions', sortable: false }
  ]

  private registros: Array<GrupoInterface> = []

  private viewItem ( item: GrupoInterface ) {
    this.tituloForm = "Visualizar Grupo"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.exibindo
  }

  private editItem ( item: GrupoInterface ) {
    this.tituloForm = "Alterar Grupo"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.editando
  }

  private deleteItem ( item: GrupoInterface ) {
    this.tituloForm = "Excluir Grupo"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.excluindo
  }

  private getDetalhe () {

    this.registros = []

    const query: string = `
      getGrupos(pesquisa: "${this.pesquisa}") {
        idGrupo
        descricao
      }
    `

    this.clsApollo.query<Array<GrupoInterface>>( this, query, 'getGrupos', 'Pequisando Grupos...' ).then( rs => {
      this.registros = rs
    } )
  }

  private btPesquisar (): void {
    this.getDetalhe()
  }

  private get getFormats (): typeof getFormats {
    return getFormats
  }

}
