import { AulaExercicioInterface, AulaMidiaInterface } from '@/interfaces/backend/AulaInterfaces'
import { elementoHTMLType, ComponenteExercicioInterface, ComponenteExercicioType, ExercicioConteudoInterface } from '@/types/backend/ExerciciosDataType'
import { Component, Prop, Vue } from 'vue-property-decorator'

import ExercicioPreView from './ExercicioPreView.vue'
import { VueEditor } from "vue2-editor"

@Component( {
  components: {
    ExercicioPreView,
    VueEditor
  }
} )
export default class ExercicioEditorCls extends Vue {

  @Prop( { default: '' } )
  private titulo: string

  @Prop( { default: () => { return {} } } )
  private aulaExercicio: Partial<AulaExercicioInterface>

  private rsAulaExercicioEdicao: Partial<AulaExercicioInterface> = {}
  private rsExercicio: Array<Partial<ExercicioConteudoInterface>> = []

  @Prop( { default: () => [] } )
  private rsMidias: Array<AulaMidiaInterface>

  private mounted () {
    this.rsAulaExercicioEdicao = this.aulaExercicio

    if ( typeof this.rsAulaExercicioEdicao.propriedades == "string" ) {
      this.rsExercicio = JSON.parse( <string>this.rsAulaExercicioEdicao.propriedades )
    }
  }

  private get customToolbarVueEditor (): Array<Array<string | Record<string, unknown>>> {
    return [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: "center" }, { align: "" }, { align: "right" }, { align: "justify" }],
      ["code-block"],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme,

    ]
  }

  private get componentesDisponiveis (): Array<ComponenteExercicioInterface> {

    const retorno: Array<ComponenteExercicioInterface> = [...ComponenteExercicioType]

    // LOGICA - Verificar Quais tipos de mídias para adicionar

    this.rsMidias.forEach( midia => {
      retorno.push( {
        descricao: midia.nomeArquivo,
        elementoHTML: elementoHTMLType.IMAGEM,
        valorPadrao: midia.idAulaMidia ? midia.idAulaMidia.toString() : '',
        icone: 'mdi-image'
      } )
    } )

    return retorno
  }

  private btAdicionar ( componente: ComponenteExercicioInterface ) {

    const adiconar: Partial<ExercicioConteudoInterface> = {}

    if ( componente.elementoHTML == elementoHTMLType.TEXTO ) {
      adiconar.alinhamento = 'left'
      adiconar.elementoHTML = elementoHTMLType.TEXTO
      adiconar.texto = {
        conteudo: componente.valorPadrao
      }
    } else if ( componente.elementoHTML == elementoHTMLType.TESTE ) {
      adiconar.alinhamento = 'left'
      adiconar.elementoHTML = elementoHTMLType.TESTE
      adiconar.teste = {
        opcoes: JSON.parse( componente.valorPadrao ),
        opcoesCorretas: [],
        ptsTeste: 0
      }
    } else if ( componente.elementoHTML == elementoHTMLType.IMAGEM ) {
      adiconar.alinhamento = 'left'
      adiconar.elementoHTML = elementoHTMLType.IMAGEM
      adiconar.imagem = {
        idAulaMidia: parseInt( componente.valorPadrao ),
        height: '',
        maxHeight: '',
        maxWidth: '',
        width: '50%'
      }
    } else if ( componente.elementoHTML == elementoHTMLType.COMPLETE ) {
      adiconar.alinhamento = 'left'
      adiconar.elementoHTML = elementoHTMLType.COMPLETE
      adiconar.complete = [{
        texto: componente.valorPadrao
      }]
    } else if ( componente.elementoHTML == elementoHTMLType.AUDIO ) {
      adiconar.alinhamento = 'left'
      adiconar.elementoHTML = elementoHTMLType.TESTE
      adiconar.audio = {
        idAulaMidia: parseInt( componente.valorPadrao ),
        icone: 'mdi-play'
      }
    }

    this.rsExercicio.push( adiconar )

  }

  private btConfirmar () {
    this.$emit( 'confirmarEdicaoExercicio', this.rsAulaExercicioEdicao, this.rsExercicio )
  }

  private btCancelar () {
    this.$emit( 'cancelarEdicaoExercicio' )
  }

  private atualizarExercicio ( editado: Partial<ExercicioConteudoInterface>[] ) {
    this.rsExercicio = JSON.parse( JSON.stringify( editado ) )
  }

}