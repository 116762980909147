import Vue from "vue"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"

import { ExercicioTesteInterface } from "@/types/backend/ExerciciosDataType"

@Component
export default class InputTesteCls extends Vue {

  private cadastroTeste: ExercicioTesteInterface = { opcoes: [], opcoesCorretas: [], ptsTeste: 0 }

  private respostaEditada: Array<number> = []

  private emEdicao: boolean = false

  @Prop()
  private value: ExercicioTesteInterface

  @Prop()
  private aulaConcluida: boolean

  @Prop( {
    default: () => []
  } )
  private respostas: Array<number>

  private mounted () {
    this.onChangeValue()
  }

  @Watch( 'value' )
  private onChangeValue () {

    this.respostaEditada = this.respostas

    if ( this.value && this.value.opcoes.length > 0 ) {
      this.cadastroTeste = JSON.parse( JSON.stringify( this.value ) )
    } else {
      this.cadastroTeste = { opcoes: [], opcoesCorretas: [], ptsTeste: 0 }
    }

    this.keyRefresh++
  }

  private onChangeResposta () {

    const respostaCorreta: boolean = this.cadastroTeste.opcoesCorretas.length === this.respostaEditada.length &&
      this.cadastroTeste.opcoesCorretas.every( element => {
        return this.respostaEditada.includes( element );
      } )

    this.$emit( 'onChangeResposta', this.respostaEditada, respostaCorreta ? this.value.ptsTeste : 0, respostaCorreta )
    this.emEdicao = false

  }

  private keyRefresh: number = 0

}