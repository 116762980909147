import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { clsUtils, validadoresFrontEnd } from 'zlib-utils'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { StatusFormType } from '@/utils/dataTypes'
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'
import { AulaExercicioInterface, AulaMidiaInterface } from '@/interfaces/backend/AulaInterfaces'
import { AulaMidiaType } from '@/types/backend/AulaMidiaDataType'

import ExercicioEditor from './ExercicioEditor.vue'
import { ExercicioConteudoInterface } from '@/types/backend/ExerciciosDataType'

@Component( {
  components: {
    ExercicioEditor
  }
} )
export default class ExercicioCls extends Vue {

  private keyRefresh: number = 1

  @Prop( {
    default: 0
  } )
  private idAula: number

  @Watch( 'idAula' )
  private onChangeValue () {
    this.getDetalhe()
  }

  @Prop( { default: '' } )
  private titulo: string

  private rsMidias: Array<AulaMidiaInterface> = []

  private validadoresFrontEnd = validadoresFrontEnd
  private formValid = false

  private editedItem: AulaExercicioInterface = this.resetModel()
  private tmpRegistro: AulaExercicioInterface = this.resetModel()

  private registros: Array<AulaExercicioInterface> = []

  private resetModel (): AulaExercicioInterface {
    return {
      idAula: 0,
      titulo: '',
      propriedades: {},
      ordem: 0,
      correcao: ''
    }
  }
  private status: StatusFormType = StatusFormType.inicial

  private tituloForm: string = "Trilhas"

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private btCancelar (): void {
    this.status = StatusFormType.inicial
  }

  private btIncluir (): void {

    this.tituloForm = 'Incluir Exercício'

    this.status = StatusFormType.editando

    if ( EMDESENVOLVIMENTO ) {
      this.editedItem = {
        idAula: this.idAula,
        titulo: 'Exercicio de Testes Inclusao',
        propriedades: {},
        ordem: this.editedItem.ordem = this.registros.length + 1,
        correcao: 'Correção do Exercício'
      }
    } else {
      this.editedItem = this.resetModel()
      this.editedItem.ordem = this.registros.length + 1
    }

    this.getMidias()

    this.$emit( 'iniciarEdicaoExercicio' )

    this.keyRefresh++

  }
  /*
    private btConfirmar (): void {
  
      if ( this.status == StatusFormType.editando ) {
  
        if ( ( this.$refs.form as Vue & { validate: () => boolean } ).validate() ) {
  
          const mutation: string = `
              updateExercicio(dados: ${clsUtils.ConverterEmGql( this.editedItem )} ) {
                ok
                mensagem
              }
            `
  
          this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'updateExercicio', 'Atualizando Exercício' ).then( rs => {
            if ( rs.ok ) {
              this.status = StatusFormType.inicial
              this.getDetalhe()
            }
          } )
  
        }
      } else if ( this.status == StatusFormType.excluindo ) {
  
        const mutation: string = `
          delExercicio(idExercicio: ${this.editedItem.idAulaExercicio} ) {
            ok
            mensagem
          }
        `
  
        this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'delExercicio', 'Excluindo Exercício....' ).then( rs => {
          if ( rs.ok ) {
            this.status = StatusFormType.inicial
            this.getDetalhe()
          } else {
  
            MensagemStoreModule.exibirMensagem( {
              mensagem: rs.mensagem,
              tipo: TipoMensagemType.erro,
              titulo: 'Erro!!!'
            } )
  
          }
        } )
  
      }
    }
  */
  private cabecalhos = [
    { text: 'Titulo', value: 'titulo' },
    { text: 'Peso', value: 'ptsAproveitamento' },
    { text: 'Ordem', value: 'ordem' },
    { text: 'Ações', value: 'actions', sortable: false }
  ]

  private viewItem ( item: AulaExercicioInterface ) {
    this.tituloForm = "Visualizar Exercício"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.exibindo

    this.keyRefresh++

  }

  private editItem ( item: AulaExercicioInterface ) {

    this.tituloForm = "Alterar Exercício"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.editando

    this.getMidias()

    this.$emit( 'iniciarEdicaoExercicio' )

    this.keyRefresh++

  }

  private deleteItem ( item: AulaExercicioInterface ) {
    this.tituloForm = "Excluir Exercício"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.excluindo
  }

  private getDetalhe () {

    const query: string = `
      getAulasExercicios(idAula: ${this.idAula}) {
        idAulaExercicio
        idAula
        titulo
        propriedades
        ordem
        correcao
      }
    `

    this.clsApollo.query<Array<AulaExercicioInterface>>( this, query, 'getAulasExercicios', 'Pequisando Exercícios...' ).then( rs => {
      this.registros = rs
    } ).catch( () => {
      this.registros = []
    } )
  }
  /*
    private btPesquisar (): void {
      this.getDetalhe()
    }
  */
  // Drag And Drop

  private startDrag ( evt: DragEvent, idExercicio: number, ordem: number ) {

    if ( evt.dataTransfer ) {
      // console.log('START DRAG')
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData( 'idAulaExercicioDrag', idExercicio.toString() )
      evt.dataTransfer.setData( 'ordemDrag', ordem.toString() )
    }

  }

  private onDrop ( evt: DragEvent, idAulaExercicioDrop: number, ordemDrop: number ) {

    if ( evt.dataTransfer ) {

      const idAulaExercicioDrag = parseInt( evt.dataTransfer.getData( 'idAulaExercicioDrag' ) )
      const ordemDrag = parseInt( evt.dataTransfer.getData( 'ordemDrag' ) )

      this.dragAndDrop( idAulaExercicioDrag, ordemDrag, idAulaExercicioDrop, ordemDrop )

    }
  }

  private dragAndDrop ( idAulaExercicioDrag: number, ordemDrag: number, idAulaExercicioDrop: number, ordemDrop: number ): void {

    const mutation: string = `
        trocarOrdemAulaExercicio(idAulaExercicioDrag: ${idAulaExercicioDrag}, ordemDrag: ${ordemDrag}, idAulaExercicioDrop: ${idAulaExercicioDrop}, ordemDrop: ${ordemDrop}) {
          ok
          mensagem
        }      
      `

    this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'trocarOrdemAulaExercicio', 'Alterando Ordem dos Exercícios...' ).then( rs => {
      if ( rs.ok ) {

        this.getDetalhe()

      } else {

        MensagemStoreModule.exibirMensagem( {
          mensagem: rs.mensagem,
          tipo: TipoMensagemType.erro,
          titulo: 'Erro'

        } )

      }

    } )
  }

  private getMidias (): Promise<boolean> {

    this.rsMidias = []

    const query: string = `
      getAulaMidias(idAula: ${this.idAula} tipo: ${AulaMidiaType.exercicio}) {
        idAulaMidia
        nomeArquivo
      }
    `

    return this.clsApollo.query<Array<AulaMidiaInterface>>( this, query, 'getAulaMidias', 'Carregando Mídias...' ).then( rs => {
      this.rsMidias = rs
      return true
    } ).catch( () => {
      return false
    } )

  }

  private confirmarEdicaoExercicio ( rsAulaExercicio: AulaExercicioInterface, rsExercicio: Array<ExercicioConteudoInterface> ): Promise<boolean> {

    const conteudoAula: AulaExercicioInterface = { ...rsAulaExercicio }

    conteudoAula.propriedades = JSON.stringify( rsExercicio )

    conteudoAula.idAula = this.idAula

    const mutation: string = `
      incluirAulaExercicio(aulaExercicio: ${clsUtils.ConverterEmGql( conteudoAula )} ) {
        ok
        mensagem
      }
    `

    return this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'incluirAulaExercicio', 'Atualizando Exercicios...' ).then( rs => {

      this.cancelarEdicaoExercicio()

      return true

    } ).catch( () => {

      this.status = StatusFormType.inicial
      return false

    } )

  }

  private btConfirmarExclusaoExercicio () {

    const mutation: string = `
        excluirAulaExercicio(idAulaExercicio: ${this.editedItem.idAulaExercicio}) {
          ok
          mensagem
        }      
      `

    this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'excluirAulaExercicio', 'Excluindo Exercício...' ).then( rs => {
      if ( rs.ok ) {

        this.getDetalhe()

        this.status = StatusFormType.inicial

      } else {

        MensagemStoreModule.exibirMensagem( {
          mensagem: rs.mensagem,
          tipo: TipoMensagemType.erro,
          titulo: 'Erro'

        } )

      }

    } )

  }

  private cancelarEdicaoExercicio () {
    this.status = StatusFormType.inicial
    this.getDetalhe()
    this.$emit( 'concluirEdicaoExercicio' )
  }

}
