import { Component, Vue } from 'vue-property-decorator'

import { LoginStoreModule } from './store/modules/LoginStore'
import { StatusStoreModule } from './store/modules/StatusStore'
import Notificacao from './layout/notificacao/Notificacao.vue'

// import { DataStoreModule } from './store/modules/DataStore';

import Mensagem from '@/components/mensagens.vue'
import store from './store'
import { EMDESENVOLVIMENTO, ENDPOINT, VERSAO_SISTEMA } from './config/backend/emDesenvolvimento'
import { MENU, MENUINTERFACE } from './config/Menu'
import { DataStoreModule } from './store/modules/DataStore'
import ClsAcesso from './utils/ClsAcesso'
import axios from 'axios'

@Component( {
  components: {
    Notificacao,
    Mensagem
  }
} )
export default class App extends Vue {

  private btBottomAtivo: string = 'home'

  private get LoginStoreModule (): typeof LoginStoreModule { return LoginStoreModule }
  private get StatusStoreModule (): typeof StatusStoreModule { return StatusStoreModule }
  private get DataStoreModule (): typeof DataStoreModule { return DataStoreModule }
  private get EMDESENVOLVIMENTO (): boolean { return EMDESENVOLVIMENTO }
  private get VERSAO_SISTEMA (): string { return VERSAO_SISTEMA }

  private get clsAcesso (): ClsAcesso { return new ClsAcesso() }

  private drawer = false
  private get items (): Array<MENUINTERFACE> {

    if ( LoginStoreModule.logado ) {
      this.getAvatar()
      return [...MENU]
    } else {
      return []
    }
  }

  // Instalação do APP via Service Worker

  private deferredPrompt: null | Event | any = null

  private created () {
    window.addEventListener( "beforeinstallprompt", e => {
      // console.log( 'beforeinstallprompt - Event Service Worker' )
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    } )

    window.addEventListener( "appinstalled", () => {
      // console.log( 'appinstalled - Event Service Worker' )
      this.deferredPrompt = null;
    } )

    window.addEventListener( 'install', ( e: any ) => {
      // console.log( 'Install - Event Service Worker' )
      e.waitUntil( caches.open( 'static' ).then( cache => {
        return cache.addAll( ['./'] )
      } ) )
    } )

    window.addEventListener( 'fetch', ( e: any ) => {
      // console.log( 'fetch - Event Service Worker' )
      e.respondWith( caches.match( e.request ).then( response => {
        return response || fetch( e.request )
      } ) )
    } )

    /*

    // Detecta se o dispositivo está no iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test( userAgent );
    }
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ( 'standalone' in window.navigator ) && ( ( window.navigator as any ).standalone );
    // Verifica se deve exibir notificação popup de instalação:
    if ( isIos() && !isInStandaloneMode() ) {
      this.deferredPrompt = true
    }
    */

  }

  private btInstall () {
    if ( this.deferredPrompt )
      this.deferredPrompt.prompt();
  }

  private btCancelar () {
    this.deferredPrompt = null
  }

  // Termino Instalação do APP via Service Worker

  private btClickMenu ( destino: string ): void {

    store.dispatch( 'refreshNotificacao', this )

    if ( destino && this.$router.currentRoute.path != destino ) {
      this.$router.push( destino )
    }

    this.drawer = false

  }

  private imagemSelfie: string = ''
  private keyRefresh: number = 1

  private getAvatar (): void {
    this.getSelfie()
  }

  private getSelfie (): void {

    if ( LoginStoreModule.token ) {

      axios.post( ENDPOINT.concat( 'getSelfie' ), {}, {
        responseType: 'json',
        headers: {
          'content-type': 'multipart/form-data',
          'authorization': LoginStoreModule.token
        }
      } ).then( rs => {
        this.imagemSelfie = rs.data.link
        this.keyRefresh++
      } )
    }
  }

  private get versaoSistema (): string {
    return VERSAO_SISTEMA
  }

}
