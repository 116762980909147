export interface ExercicioTesteInterface {
  opcoes: Array<string>
  opcoesCorretas: Array<number>
  ptsTeste: number
}

export interface ExercicioCompletarInterface {
  texto: string
}

export interface ExercicioCompletarPropriedadesInterface {
  tamanho: number
  resposta: string
  caseSensitive: boolean
  ptsTeste: number
}

export interface ExercicioConteudoInterface {

  elementoHTML: elementoHTMLType

  alinhamento: string

  imagem: {
    idAulaMidia: number
    maxWidth: string
    maxHeight: string
    width: string
    height: string
  }

  complete: Array<ExercicioCompletarInterface>

  teste: ExercicioTesteInterface

  texto: {
    conteudo: string
  }

  audio: {
    icone: string
    idAulaMidia: number
  }

}

export interface ComponenteExercicioInterface {
  descricao: string
  icone: string
  elementoHTML: elementoHTMLType
  valorPadrao: string
}

export enum elementoHTMLType {
  IMAGEM = 'img',
  TESTE = 'input-teste',
  TEXTO = 'p',
  COMPLETE = 'input-completar',
  AUDIO = 'audio'
}

export const ComponenteExercicioType: Array<ComponenteExercicioInterface> = [
  {
    descricao: 'Teste',
    icone: 'mdi-format-list-bulleted',
    elementoHTML: elementoHTMLType.TESTE,
    valorPadrao: '["Novo Teste"]'
  },
  {
    descricao: 'Texto',
    icone: 'mdi-text',
    elementoHTML: elementoHTMLType.TEXTO,
    valorPadrao: 'Texto'
  },
  /*
    {
      descricao: 'Imagem',
      icone: 'mdi-image',
      elementoHTML: elementoHTMLType.IMAGEM,
      valorPadrao: 'Imagem'
    },
    */
  {
    descricao: 'Completar Texto',
    icone: 'mdi-image',
    elementoHTML: elementoHTMLType.COMPLETE,
    valorPadrao: 'O nome do componente {"tamanho": 25, "resposta": "Complete", "caseSensitive": false, "ptsTeste": 10}, é usado para completar!'
  },
  /*
  {
    descricao: 'Áudio',
    icone: 'mdi-volume-medium',
    elementoHTML: elementoHTMLType.AUDIO,
    valorPadrao: 'Audio'
  }
  */
]