import { Component, Vue } from 'vue-property-decorator'

import { clsUtils, validadoresFrontEnd } from 'zlib-utils'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { StatusFormType } from '@/utils/dataTypes'
import { getFormats } from '@/utils/FormatarDados'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'

import { TurmaInterface } from '@/interfaces/backend/TurmaInterfaces'

import { mask } from 'vue-the-mask'
import { TipoLiberacaoAulaType } from '@/types/backend/TurmaDataTypes'
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento'
import { isNumber } from 'lodash'

@Component( {
  directives: {
    mask
  }
} )
export default class TurmaCls extends Vue {

  private MensagemErro: { [key: string]: string } = {}
  private refreshForm: number = 1

  private pesquisa: string = ''

  private validadoresFrontEnd = validadoresFrontEnd
  private formValid = false

  private editedItem: Partial<TurmaInterface> = this.resetModel()
  private tmpRegistro: Partial<TurmaInterface> = this.resetModel()

  private resetModel (): Partial<TurmaInterface> {
    return {
      idTurma: 0,
      codigoTurma: '',
      descricao: '',
      idProfessor: 0,
      tipoLiberacaoAula: TipoLiberacaoAulaType.aposAulaAnterior,
      dataInicio: new Date(),
      idCurso: 0,
      cargaHorariaPresencial: 0,
      domingo: false,
      segunda: false,
      terca: false,
      quarta: false,
      quinta: false,
      sexta: false,
      sabado: false,
      horaInicio: '',
      horaTermino: ''
    }
  }
  private status: StatusFormType = StatusFormType.inicial

  private tituloForm: string = ""

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private mounted () {
    this.getDetalhe()
  }

  private btCancelar (): void {
    this.status = StatusFormType.inicial
  }

  private btIncluir (): void {

    this.tituloForm = 'Incluir Turma'

    this.status = StatusFormType.incluindo
    this.editedItem = this.resetModel()

    if ( EMDESENVOLVIMENTO ) {
      this.editedItem = {
        ...this.editItem,
        codigoTurma: 'Codigo',
        descricao: 'Descricao',
        horaInicio: '20:00',
        horaTermino: '19:00',
        cargaHorariaPresencial: 100
      }
    }

  }

  private get isTemCargaHorariaPresencial (): boolean {

    return !( !this.editedItem.cargaHorariaPresencial || isNaN( this.editedItem.cargaHorariaPresencial ) || this.editedItem.cargaHorariaPresencial == 0 )

  }

  private validarCadastroTurma (): boolean {
    let retorno: boolean = true
    this.MensagemErro = {}

    if ( this.editedItem.horaInicio && this.editedItem.horaTermino && this.editedItem.horaInicio >= this.editedItem.horaTermino ) {
      this.MensagemErro.horaInicio = 'Deve ser < Término'
      this.MensagemErro.horaTermino = 'Deve ser > Início'
    }

    if ( this.isTemCargaHorariaPresencial && !(
      this.editedItem.domingo ||
      this.editedItem.segunda ||
      this.editedItem.terca ||
      this.editedItem.quarta ||
      this.editedItem.quinta ||
      this.editedItem.sexta ||
      this.editedItem.sabado ) ) {
      this.MensagemErro.erroSelecaoDias = 'Selecione um Dia na Semana.'
      retorno = false
    }

    if ( !this.editedItem.cargaHorariaPresencial || isNaN( this.editedItem.cargaHorariaPresencial ) ) {
      this.MensagemErro.cargaHorariaPresencial = 'Carga Horária Inválida!'
    }

    if ( !retorno ) this.refreshForm++

    return retorno
  }

  private btConfirmar (): void {

    if ( this.status == StatusFormType.incluindo || this.status == StatusFormType.editando ) {

      if ( ( this.$refs.form as Vue & { validate: () => boolean } ).validate() && this.validarCadastroTurma() ) {

        const mutation: string = `
          updateTurma(dados: ${clsUtils.ConverterEmGql(
          { ...this.editedItem } )} 
          ) {
            ok
            mensagem
          }
        `

        this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'updateTurma', 'Atualizando Usuário' ).then( rs => {
          if ( rs.ok ) {
            this.status = StatusFormType.inicial
            this.getDetalhe()
          } else {

            MensagemStoreModule.exibirMensagem( {
              mensagem: rs.mensagem,
              tipo: TipoMensagemType.erro,
              titulo: 'Erro!!!'
            } )

          }

        } )

      }
    } else if ( this.status == StatusFormType.excluindo ) {

      const mutation: string = `
        delTurma(idTurma: ${this.editedItem.idTurma} ) {
          ok
          mensagem
        }
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'delTurma', 'Excluindo Usuário....' ).then( rs => {
        if ( rs.ok ) {
          this.status = StatusFormType.inicial
          this.getDetalhe()
        } else {

          MensagemStoreModule.exibirMensagem( {
            mensagem: rs.mensagem,
            tipo: TipoMensagemType.erro,
            titulo: 'Erro!!!'
          } )

        }
      } )

    }
  }

  private cabecalhos = [
    { text: 'Código', value: 'codigoTurma' },
    { text: 'Descrição', value: 'descricao' },
    { text: 'Professor', value: 'idProfessor' },
    { text: 'Tipo Liberação', value: 'tipoLiberacaoAula' },
    { text: 'Início', value: 'dataInicio' },
    { text: 'Curso', value: 'idCurso' },
    { text: 'C.H. Presencial', value: 'cargaHorariaPresencial' },
    { text: 'D', value: 'domingo' },
    { text: 'S', value: 'segunda' },
    { text: 'T', value: 'terca' },
    { text: 'Q', value: 'quarta' },
    { text: 'Q', value: 'quinta' },
    { text: 'S', value: 'sexta' },
    { text: 'S', value: 'sabado' },
    { text: 'Início', value: 'horaInicio' },
    { text: 'Término', value: 'horaTermino' }
  ]

  private registros: Array<TurmaInterface> = []

  private viewItem ( item: TurmaInterface ) {
    this.tituloForm = "Visualizar Usuário"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.exibindo
  }

  private editItem ( item: TurmaInterface ) {
    this.tituloForm = "Alterar Usuário"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.editando
  }

  private deleteItem ( item: TurmaInterface ) {
    this.tituloForm = "Excluir Usuário"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.excluindo
  }

  private getDetalhe () {

    this.registros = []

    const query: string = `
      getTurmas(pesquisa: "${this.pesquisa}") {
        idTurma
        codigoTurma
        descricao
        idProfessor
        tipoLiberacaoAula
        dataInicio
        idCurso
        cargaHorariaPresencial
        domingo
        segunda
        terca
        quarta
        quinta
        sexta
        sabado
        horaInicio
        horaTermino
    }
    `

    console.log( query )
    this.clsApollo.query<Array<TurmaInterface>>( this, query, 'getTurmas', 'Pequisando Turmas...' ).then( rs => {
      this.registros = rs
    } )
  }

  private btPesquisar (): void {
    this.getDetalhe()
  }

  private get getFormats (): typeof getFormats {
    return getFormats
  }

  private refresh (): void {
    this.status = StatusFormType.inicial
    this.getDetalhe()
  }

}
