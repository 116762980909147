



























































































































































































































































import s from './TurmaCls'
export default s
