


































import s from './CadastroCompletarCls'
export default s
