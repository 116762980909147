import Vue from "vue"
import Component from "vue-class-component"

import ClsApolloClient from "@/utils/ClsApolloClient"
import { StatusFormType } from "@/utils/dataTypes"

import CardCurso from '@/components/cardCurso.vue'
import { RespostaPadraoInterface } from "@/interfaces/backend/PadraoInterfaces"
import { AlunoCursoTypeInterface } from "@/interfaces/backend/AlunoInterfaces"

import { LoginStoreModule } from "@/store/modules/LoginStore"
import axios from "axios"
import { EMDESENVOLVIMENTO, ENDPOINT } from "@/config/backend/emDesenvolvimento"

const DEBUG_MEUS_CURSOS: boolean = EMDESENVOLVIMENTO && false

@Component( { components: { CardCurso } } )
export default class MeusCursosCls extends Vue {

  private get nomeAluno (): string { return LoginStoreModule.nome }
  private imagemSelfie: string = ''

  private keyRefresh: number = 0

  private status: StatusFormType = StatusFormType.inicial

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private rsMatriculas: Array<AlunoCursoTypeInterface> = []

  private mounted () {
    this.getSelfie()
    this.getMeusCursos()
  }

  private getMeusCursos () {

    const query: string = `
      getMeusCursos {
        idMatricula
          idCurso
          idUsuario
          titulo
          totAproveitamento
          totConclusao
          ptsAcertoAproveitamento
          ptsConclusao
          pesquisaSatisfacao
          ultimoAcesso
          concluido
      }
    `

    this.clsApollo.query<Array<AlunoCursoTypeInterface>>( this, query, 'getMeusCursos', 'Pequisando Matrículas...' ).then( rs => {

      DEBUG_MEUS_CURSOS ? console.log( 'Get Meus Cursos em MeusCursosCLS: ', JSON.stringify( rs ) ) : ""
      this.rsMatriculas = rs
      this.keyRefresh++
    } ).catch( () => {
      this.rsMatriculas = []
      this.keyRefresh++
    } )

  }

  // LOGICA - Revisar - ver utilização
  private avaliarCurso ( idCurso: number, avaliacao: number ) {
    const mutation: string = `
      avaliarCurso(idCurso: ${idCurso}, avaliacao: ${avaliacao}) {
        ok
        mensagem
      }
    `

    this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'avaliarCurso', 'Avaliando Curso...' ).then( rs => {
      if ( rs.ok ) {
        this.getMeusCursos()
      }
    } )

  }

  private getSelfie (): void {

    if ( LoginStoreModule.token ) {

      axios.post( ENDPOINT.concat( 'getSelfie' ), {}, {
        responseType: 'json',
        headers: {
          'content-type': 'multipart/form-data',
          'authorization': LoginStoreModule.token
        }
      } ).then( rs => {
        this.imagemSelfie = rs.data.link
        this.keyRefresh++
      } )
    }
  }

  // LOGICA - Revisar - ver utilização
  private exibirAssunto ( idMatricula: number, idCurso: number ) {

    console.log( 'exibir Assunto...:', idCurso, idMatricula )

  }

}