







































































import s from './AulaTagCls'
export default s
