/* eslint-disable */
//@ts-ignore
import { NotificacaoInterface } from '@/interfaces/backend/NotificacaoInterfaces'
import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '..'

export interface StateNotificacaoInterface extends NotificacaoInterface {
  refreshNotificacao ( payload: any ): Promise<NotificacaoInterface>;
}

// @Module( { namespaced: true, name: 'login' } )
@Module( { dynamic: true, store, name: 'notificacao' } )
class NotificacaoStore extends VuexModule implements StateNotificacaoInterface {

  novosLeads: Array<string> = []

  @Mutation
  notificacao ( notificacao: NotificacaoInterface ): void {
    // this.novosLeads = notificacao.novosLeads
  }

  @Action( { commit: 'notificacao', rawError: true } )
  async refreshNotificacao ( payload: any ): Promise<NotificacaoInterface> {

    // TODO - Implementar Notificacoes
    /*
    const clsApolloClient: ClsApolloClient = new ClsApolloClient()
    const status = `
      getNovosLeads
    `

    const rs = await clsApolloClient.query<Array<string>>( payload, status, 'getNovosLeads' )
    */
    return {
      // novosLeads: rs
    }


  }

}

export const NotificacaoStoreModule: StateNotificacaoInterface = getModule( NotificacaoStore, store )
