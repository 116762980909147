import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { UsuarioInterface } from '@/interfaces/backend/UsuarioInterfaces'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'
import { MAXIMO_ERRO_LOGIN } from '@/types/backend/AcessosDataType'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { validadoresFrontEnd } from 'zlib-utils'

/*
, Prop, Watch
import { StatusFormType } from '@/utils/dataTypes'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'
import { GrupoInterface } from '@/interfaces/backend/GrupoInterface'
*/

@Component
export default class ResetUsuarioCls extends Vue {

  private validadoresFrontEnd = validadoresFrontEnd

  @Prop()
  private rsUsuario: UsuarioInterface

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private exibirSenha: boolean = false
  private senha: string = ""

  private formValid: boolean = false

  private get isBloqueado (): boolean {
    return !this.rsUsuario.ativo || ( this.rsUsuario.tentativaLogin ? this.rsUsuario.tentativaLogin > MAXIMO_ERRO_LOGIN : false )
  }

  private btBloquear (): void {

    const query: string = `
      updateAtivo(idUsuario: ${this.rsUsuario.idUsuario}, ativo: false) {
        ok
        mensagem
      }
    `
    this.clsApollo.mutation<RespostaPadraoInterface>( this, query, 'updateAtivo', 'Bloqueando Usuário...' ).then( rs => {
      if ( rs.ok ) {
        this.$emit( 'refresh' )
      } else {
        MensagemStoreModule.exibirMensagem( { titulo: 'Bloqueio', mensagem: 'Erro ao Bloquear Usuário!', tipo: TipoMensagemType.erro } )
      }

    } )

  }

  private btDesbloquear (): void {

    const query: string = `
    updateAtivo(idUsuario: ${this.rsUsuario.idUsuario}, ativo: true) {
      ok
      mensagem
    }
    `

    this.clsApollo.mutation<RespostaPadraoInterface>( this, query, 'updateAtivo', 'Desbloqueando Usuário' ).then( rs => {
      if ( rs.ok ) {
        this.$emit( 'refresh' )
      } else {
        MensagemStoreModule.exibirMensagem( { titulo: 'Bloqueio', mensagem: 'Erro ao Desbloquear Usuário!', tipo: TipoMensagemType.erro } )
      }

    } )

  }

  private btAtribuirSenha (): void {

    if ( ( this.$refs.form as Vue & { validate: () => boolean } ).validate() ) {
      const mutation = `
        atribuirSenha(senha: "${this.senha}", idUsuario: ${this.rsUsuario.idUsuario}) {
          ok
          mensagem
        }
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'atribuirSenha', 'Alterando Senha....' ).then( rs => {
        if ( rs.ok ) {

          this.senha = ""

          MensagemStoreModule.exibirMensagem( {
            mensagem: rs.mensagem,
            tipo: TipoMensagemType.aviso,
            titulo: 'Sucesso!!!'
          } )

        } else {

          MensagemStoreModule.exibirMensagem( {
            mensagem: rs.mensagem,
            tipo: TipoMensagemType.erro,
            titulo: 'Erro!!!'
          } )

        }
      } )
    }

  }

  private btResetSenhaPorEmail (): void {

    const mutation = `
      esqueciSenha(email: "${this.rsUsuario.email}" ) {
        ok
        mensagem
      }
    `

    this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'esqueciSenha', 'Enviando e-mail...' ).then( rs => {

      if ( rs.ok ) {
        MensagemStoreModule.exibirMensagem( { titulo: 'Sucesso!', mensagem: 'Reset Enviado por e-mail!', tipo: TipoMensagemType.aviso } )
      } else {
        MensagemStoreModule.exibirMensagem( { titulo: 'Erro', mensagem: 'Erro ao Enviar e-mail!', tipo: TipoMensagemType.erro } )
      }

    } )

  }
  /*

  private formValid = false

  private editedItem: Array<number> = []
  private tmpRegistro: Array<number> = []

  private status: StatusFormType = StatusFormType.editando

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  //Grupos que estão cadastrados
  private rsGrupos: Array<GrupoInterface> = []



  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  // v-Select de Grupos

  private isLoading: boolean = false

  private pesquisa: string = ""

  private grupoSelecionado: GrupoInterface | null = null

  // Grupos que podem ser incluidos
  private listaGrupos: Array<GrupoInterface> = []

  private btPesquisar (): void {

    this.isLoading = true

    this.listaGrupos = []

    this.pesquisa = this.pesquisa ? this.pesquisa : ""

    const query: string = `
      getGrupos(pesquisa: "${this.pesquisa}") {
        idGrupo
        descricao
      }
    `
    this.clsApollo.query<Array<GrupoInterface>>( this, query, 'getGrupos', 'Pesquisando Grupos...' ).then( rs => {
      this.isLoading = false
      this.listaGrupos = rs.filter( lista => this.rsGrupos.findIndex( usuario => usuario.idGrupo == lista.idGrupo ) < 0 )
    } )

  }

  private btIncluirGrupo ( qual: GrupoInterface ): void {

    if ( qual && typeof qual == "object" ) {

      const mutation: string = `
        incluirGrupoUsuario(idUsuario: ${this.idUsuario}, idGrupo: ${qual.idGrupo} ) {
          ok
          mensagem
        }      
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'incluirGrupoUsuario', 'Incluindo Usuário no Grupo...' ).then( rs => {
        if ( rs.ok ) {
          this.listaGrupos = this.listaGrupos.filter( x => x.idGrupo != qual.idGrupo )
          this.rsGrupos.push( qual )
        } else {

          this.erroRequisicao( 'Erro ao Incluir Usuário do Grupo! Verifique Acesso!' )

        }

      } )

    }
  }

  private getItems (): Promise<boolean> {

    this.rsGrupos = []

    const query: string = `
      getUsuarioGrupo(idUsuario: ${this.idUsuario}) {
        idGrupo
        descricao
      }
    `

    return this.clsApollo.query<Array<GrupoInterface>>( this, query, 'getUsuarioGrupo', 'Obtendo Grupos Cadastrados...' ).then( rs => {
      this.rsGrupos = rs
      return true
    } ).catch( () => {
      return false
    } )

  }

  public btCancelar (): void {
    this.editedItem = [...this.tmpRegistro]
  }

  private cabecalhos = [
    { text: 'Grupo', value: 'descricao' },
    { text: 'Ações', value: 'actions', sortable: false }
  ]

  private btExcluirGrupo ( qual: GrupoInterface ): void {
    if ( qual ) {

      const mutation: string = `
        excluirGrupoUsuario(idGrupo: ${qual.idGrupo}, idUsuario: ${this.idUsuario}) {
          ok
          mensagem
        }      
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'excluirGrupoUsuario', 'Excluindo Usuário do Grupo...' ).then( rs => {
        if ( rs.ok ) {
          this.rsGrupos = this.rsGrupos.filter( x => x.idGrupo != qual.idGrupo )

          this.listaGrupos.push( qual )

        } else {

          this.erroRequisicao( 'Erro ao Excluir Usuário do Grupo! Verifique Acesso!' )

        }

      } )

    }
  }

  private erroRequisicao ( mensagem: string ): void {
    this.listaGrupos = []
    this.getItems().finally( () => {

      MensagemStoreModule.exibirMensagem( {
        mensagem: mensagem,
        tipo: TipoMensagemType.erro,
        titulo: 'Erro'

      } )

    } )

  }
  */

}