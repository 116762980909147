









































import s from './PlayAvaliacaoCls'
export default s
