import { AulaMidiaInterface } from '@/interfaces/backend/AulaInterfaces'
import ClsEstilo from '@/utils/ClsEstilo'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import ExercicioEditar from './ExercicioEditar.vue'

import InputTeste from '@/components/teste/InputTeste.vue'
import InputCompletar from '@/components/completar/InputCompletar.vue'
import { elementoHTMLType, ExercicioConteudoInterface } from '@/types/backend/ExerciciosDataType'

@Component( {
  components: {
    ExercicioEditar,
    InputCompletar,
    InputTeste
  }
} )
export default class ExercicioPreViewCls extends Vue {

  private tmpRespostaTeste: Array<string> = []

  private indiceEdicao = -1

  private emEdicao = false

  private keyRefresh: number = 1

  private itemEdicao: ExercicioConteudoInterface | null = null

  private rsExercicioEdicao: Array<ExercicioConteudoInterface> = []

  @Prop( { default: () => [] } )
  private rsExercicio: Array<ExercicioConteudoInterface>

  @Watch( 'rsExercicio' )
  private onChangeRsExercicio () {
    this.rsExercicioEdicao = JSON.parse( JSON.stringify( this.rsExercicio ) )
  }

  @Prop( {
    default: () => { return [] }
  } )
  private rsMidias: Array<AulaMidiaInterface>

  private btEditar ( index: number ): void {

    this.indiceEdicao = index
    this.itemEdicao = this.rsExercicioEdicao[this.indiceEdicao]
    this.emEdicao = true
  }

  /**
   * Disparado após a edição do conteúdo.
   * @param editado Se foi confirmada a edição
   * @param novoValor Novo Valor após edição
   */
  private confirmarEdicaoExercicio ( conteudo: ExercicioConteudoInterface ): void {
    this.rsExercicioEdicao[this.indiceEdicao] = { ...conteudo }
    this.indiceEdicao = -1
    this.emEdicao = false

    this.atualizarExercicio()

    this.keyRefresh++
  }

  private cancelarEdicaoExercicio () {
    this.indiceEdicao = -1
    this.emEdicao = false

    this.keyRefresh++
  }

  private confirmarExcluirExercicio () {

    this.rsExercicioEdicao.splice( this.indiceEdicao, 1 )

    this.indiceEdicao = -1
    this.emEdicao = false

    this.atualizarExercicio()

  }

  private startDrag ( evt: DragEvent, indice: string ) {
    if ( evt.dataTransfer ) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData( 'indiceDrag', indice )
    }
  }

  private onDrop ( evt: DragEvent, indiceDrop: string ) {
    if ( evt.dataTransfer ) {

      const tmpItem: ExercicioConteudoInterface = JSON.parse( JSON.stringify( this.rsExercicioEdicao[parseInt( indiceDrop )] ) )

      const indiceDrag = evt.dataTransfer.getData( 'indiceDrag' )

      this.rsExercicioEdicao[parseInt( indiceDrop )] = JSON.parse( JSON.stringify( this.rsExercicioEdicao[parseInt( indiceDrag )] ) )
      this.rsExercicioEdicao[parseInt( indiceDrag )] = tmpItem

      // this.keyRefresh++

      this.atualizarExercicio()

    }
  }

  private tmpClsEstilo: ClsEstilo = new ClsEstilo()

  private get clsEstilo (): ClsEstilo {
    return this.tmpClsEstilo
  }

  private get elementoHTMLType (): typeof elementoHTMLType {
    return elementoHTMLType
  }

  private atualizarExercicio () {
    this.$emit( 'atualizarExercicio', this.rsExercicioEdicao )
    // this.keyRefresh++
  }

}
