






























































import s from './AulaMidiaCls'
export default s
