import { Component, Prop, Vue } from 'vue-property-decorator'

import { clsUtils, validadoresFrontEnd } from 'zlib-utils'
import ClsApolloClient from '@/utils/ClsApolloClient'
import { RespostaPadraoInterface } from '@/interfaces/backend/PadraoInterfaces'
import { StatusFormType } from '@/utils/dataTypes'
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento'
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore'
import { CursoAssuntoTrilhaInterface } from '@/interfaces/backend/CursoInterfaces'

import CursoAssuntoTrilhaAula from './CursoAssuntoTrilhaAula.vue'

@Component( {
  components: {
    CursoAssuntoTrilhaAula
  }
} )
export default class CursoAssuntoTrilhaCls extends Vue {

  // private pesquisa: string = ''

  @Prop( {
    default: 0
  } )
  private idCursoAssunto: number

  @Prop( {
    default: ''
  } )
  private titulo: string

  private validadoresFrontEnd = validadoresFrontEnd
  private formValid = false

  private editedItem: Partial<CursoAssuntoTrilhaInterface> = this.resetModel()
  private tmpRegistro: Partial<CursoAssuntoTrilhaInterface> = this.resetModel()

  private registros: Array<CursoAssuntoTrilhaInterface> = []

  private resetModel (): Partial<CursoAssuntoTrilhaInterface> {
    return {
      idCursoAssunto: this.idCursoAssunto,
      descricao: '',
      titulo: '',
      ordem: 0
    }
  }
  private status: StatusFormType = StatusFormType.inicial

  private tituloForm: string = "Trilhas"

  private get StatusFormType (): typeof StatusFormType {
    return StatusFormType
  }

  private clsApollo: ClsApolloClient = new ClsApolloClient()

  private mounted () {
    this.getDetalhe()
  }

  private btCancelar (): void {
    this.status = StatusFormType.inicial
  }

  private btIncluir (): void {

    this.tituloForm = 'Incluir Trilha'

    this.status = StatusFormType.editando

    if ( EMDESENVOLVIMENTO ) {
      this.editedItem = {
        idCursoAssunto: this.idCursoAssunto,
        descricao: 'Descrição Assunto Trilha Teste 01',
        titulo: 'Título Assunto Trilha Teste 01',
        ordem: this.editedItem.ordem = this.registros.length + 1
      }
    } else {
      this.editedItem = this.resetModel()
      this.editedItem.ordem = this.registros.length + 1
    }
  }

  private btConfirmar (): void {

    if ( this.status == StatusFormType.editando ) {

      if ( ( this.$refs.form as Vue & { validate: () => boolean } ).validate() ) {

        const mutation: string = `
            updateCursoAssuntoTrilha(dados: ${clsUtils.ConverterEmGql( this.editedItem )} ) {
              ok
              mensagem
            }
          `

        this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'updateCursoAssuntoTrilha', 'Atualizando Trilha de Assunto de Curso' ).then( rs => {
          if ( rs.ok ) {
            this.status = StatusFormType.inicial
            this.getDetalhe()
          }
        } )

      }
    } else if ( this.status == StatusFormType.excluindo ) {

      const mutation: string = `
        delCursoAssuntoTrilha(idCursoAssuntoTrilha: ${this.editedItem.idCursoAssuntoTrilha} ) {
          ok
          mensagem
        }
      `

      this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'delCursoAssuntoTrilha', 'Excluindo Trilha de Assunto do Curso....' ).then( rs => {
        if ( rs.ok ) {
          this.status = StatusFormType.inicial
          this.getDetalhe()
        } else {

          MensagemStoreModule.exibirMensagem( {
            mensagem: rs.mensagem,
            tipo: TipoMensagemType.erro,
            titulo: 'Erro!!!'
          } )

        }
      } )

    }
  }

  private cabecalhos = [
    { text: 'Título', value: 'titulo' },
    { text: 'Descrição', value: 'descricao' },
    { text: 'Ordem', value: 'ordem' },
    { text: 'Ações', value: 'actions', sortable: false }
  ]

  private viewItem ( item: CursoAssuntoTrilhaInterface ) {
    this.tituloForm = "Visualizar Trilha"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.exibindo
  }

  private editItem ( item: CursoAssuntoTrilhaInterface ) {
    this.tituloForm = "Alterar Trilha"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.editando
  }

  private deleteItem ( item: CursoAssuntoTrilhaInterface ) {
    this.tituloForm = "Excluir Trilha"
    this.editedItem = Object.assign( {}, item )
    this.tmpRegistro = Object.assign( {}, item )
    this.status = StatusFormType.excluindo
  }

  private getDetalhe () {

    const query: string = `
      getCursoAssuntoTrilhas(idCursoAssunto: ${this.idCursoAssunto}) {
        idCursoAssuntoTrilha
        idCursoAssunto
        titulo
        descricao
        ordem
      }
    `

    this.clsApollo.query<Array<CursoAssuntoTrilhaInterface>>( this, query, 'getCursoAssuntoTrilhas', 'Pequisando Trilhas de Assuntos...' ).then( rs => {
      this.registros = rs
    } ).catch( () => {
      this.registros = []
    } )
  }

  private btPesquisar (): void {
    this.getDetalhe()
  }

  // Drag And Drop

  private startDrag ( evt: DragEvent, idCursoAssuntoTrilha: number, ordem: number ) {

    if ( evt.dataTransfer ) {
      // console.log('START DRAG')
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData( 'idCursoAssuntoTrilhaDrag', idCursoAssuntoTrilha.toString() )
      evt.dataTransfer.setData( 'ordemDrag', ordem.toString() )
    }

  }

  private onDrop ( evt: DragEvent, idCursoAssuntoTrilhaDrop: number, ordemDrop: number ) {

    if ( evt.dataTransfer ) {

      const idCursoAssuntoTrilhaDrag = parseInt( evt.dataTransfer.getData( 'idCursoAssuntoTrilhaDrag' ) )
      const ordemDrag = parseInt( evt.dataTransfer.getData( 'ordemDrag' ) )

      this.dragAndDrop( idCursoAssuntoTrilhaDrag, ordemDrag, idCursoAssuntoTrilhaDrop, ordemDrop )

    }
  }

  private dragAndDrop ( idCursoAssuntoTrilhaDrag: number, ordemDrag: number, idCursoAssuntoTrilhaDrop: number, ordemDrop: number ): void {

    const mutation: string = `
        trocarOrdemCursoAssuntoTrilha(idCursoAssuntoTrilhaDrag: ${idCursoAssuntoTrilhaDrag}, ordemDrag: ${ordemDrag}, idCursoAssuntoTrilhaDrop: ${idCursoAssuntoTrilhaDrop}, ordemDrop: ${ordemDrop}) {
          ok
          mensagem
        }      
      `

    this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'trocarOrdemCursoAssuntoTrilha', 'Alterando Ordem das Trilhas...' ).then( rs => {
      if ( rs.ok ) {

        this.getDetalhe()

      } else {

        MensagemStoreModule.exibirMensagem( {
          mensagem: rs.mensagem,
          tipo: TipoMensagemType.erro,
          titulo: 'Erro'

        } )

      }

    } )
  }

}
