import { ExercicioCompletarInterface, ExercicioCompletarPropriedadesInterface } from "@/types/backend/ExerciciosDataType"
import Vue from "vue"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Component
export default class InputCompletarCls extends Vue {

  @Prop()
  private rsExercicioCompletar: ExercicioCompletarInterface

  @Prop()
  private aulaConcluida: boolean

  @Prop()
  private chave: number

  @Prop( { default: () => [] } )
  private respostas: Array<string>

  private rsTexto: Array<string> = []
  private rsCampos: Array<ExercicioCompletarPropriedadesInterface> = []
  private rsResposta: Array<string> = []
  private rsRespostaCorreta: Array<string> = []
  private rsPtsTeste: Array<number> = []

  private qtdElementos: number = 0

  private mounted () {
    // console.log( 'MOUNTED: ', JSON.stringify( this.respostas ), JSON.stringify( this.rsResposta ) )
    // this.rsResposta = this.respostas
    // console.log( 'MOUNTED: ', JSON.stringify( this.respostas ), JSON.stringify( this.rsResposta ) )
    // console.log( typeof this.respostas )
    this.extrairOpcoes()
  }

  private extrairOpcoes (): void {

    const inicio: string = '{'
    const termino: string = '}'

    let frase: string = this.rsExercicioCompletar.texto

    let indiceInicio: number = frase.indexOf( inicio )
    let indiceTermino: number = frase.indexOf( termino )

    let indiceAtual: number = 0

    while ( indiceTermino > indiceInicio && indiceInicio >= 0 ) {

      const propriedades: ExercicioCompletarPropriedadesInterface = JSON.parse( frase.substring( indiceInicio, indiceTermino + 1 ) )

      this.rsCampos.push( propriedades )
      this.rsTexto.push( frase.substring( 0, indiceInicio ) )
      this.rsResposta.push( this.respostas && this.respostas.length > indiceAtual ? this.respostas[indiceAtual] : '' )

      this.rsRespostaCorreta.push( propriedades.resposta )
      this.rsPtsTeste.push( propriedades.ptsTeste )

      frase = frase.substring( indiceTermino + 1 )

      indiceInicio = frase.indexOf( inicio )
      indiceTermino = frase.indexOf( termino )

      indiceAtual++

    }

    this.rsTexto.push( frase )

    this.qtdElementos = Math.max( this.rsTexto.length, this.rsCampos.length )

  }

  private estiloCampoInput ( campo: ExercicioCompletarPropriedadesInterface ): string {
    return 'width: '.concat( ( campo.tamanho * 10 ).toString() ).concat( 'px;' )
  }

  private onChangeResposta () {

    if ( JSON.stringify( this.respostas ) != JSON.stringify( this.rsResposta ) ) {

      let ptsAcertoAproveitamento: number = 0

      let respostaCorreta: boolean = true

      this.rsRespostaCorreta.forEach( ( rsCorreta, indice ) => {
        if ( rsCorreta.trim() == this.rsResposta[indice].trim()
          || ( !this.rsCampos[indice].caseSensitive && rsCorreta.trim().toUpperCase() == this.rsResposta[indice].trim().toUpperCase() ) ) {
          ptsAcertoAproveitamento += this.rsPtsTeste[indice]
        } else {
          respostaCorreta = false
        }
      } )

      this.$emit( 'onChangeResposta', this.chave, this.rsResposta, ptsAcertoAproveitamento, respostaCorreta )
    }

  }

}