import { SISTEMA_PERMISSOES } from "@/types/backend/AcessosDataType"

export interface MENUFILHOSINTERFACE {
  icone: string
  texto: string
  link: string
  corBotao: string
  corIcone: string
  modulo?: string
  permissao?: string
}

export interface MENUINTERFACE {
  icone: string
  iconeAlt?: string
  texto: string
  link?: string
  corBotao?: string
  corIcone?: string
  expandido?: boolean
  modulo?: string
  permissao?: string
  filhos?: Array<MENUFILHOSINTERFACE>
}

// export const PAGINA_INICIAL_EMDESENVOLVIMENTO = '/Selfie'
// export const PAGINA_INICIAL_EMDESENVOLVIMENTO = '/Matricula'
export const PAGINA_INICIAL_EMDESENVOLVIMENTO = '/MeusCursos'
// export const PAGINA_INICIAL_EMDESENVOLVIMENTO = '/Testes'
// export const PAGINA_INICIAL_EMDESENVOLVIMENTO = '/Usuario'
// export const PAGINA_INICIAL_EMDESENVOLVIMENTO = '/Curso'
// export const PAGINA_INICIAL_EMDESENVOLVIMENTO = '/Aula'
// export const PAGINA_INICIAL_EMDESENVOLVIMENTO = '/Turma'
export const PAGINA_INICIAL_PADRAO = '/MeusCursos'
// TODO - Pagina Inicial

export const MENU: Array<MENUINTERFACE> = [
  {
    icone: 'mdi-chevron-down',
    iconeAlt: 'mdi-chevron-up',
    texto: 'Conteúdo',
    expandido: true,
    modulo: SISTEMA_PERMISSOES.CONTEUDOS.MODULO,
    permissao: SISTEMA_PERMISSOES.CONTEUDOS.PERMISSOES.MANUTENCAO,
    filhos: [
      {
        icone: 'mdi-account-details',
        texto: 'Aulas',
        link: '/Aula',
        corBotao: 'white',
        corIcone: 'blue darken-3',
        modulo: SISTEMA_PERMISSOES.AULAS.MODULO,
        permissao: SISTEMA_PERMISSOES.AULAS.PERMISSOES.MANUTENCAO
      },
      {
        icone: 'mdi-account-details',
        texto: 'Cursos',
        link: '/Curso',
        corBotao: 'white',
        corIcone: 'blue darken-3',
        modulo: SISTEMA_PERMISSOES.CURSOS.MODULO,
        permissao: SISTEMA_PERMISSOES.CURSOS.PERMISSOES.MANUTENCAO
      }]
  },

  {
    icone: 'mdi-chevron-down',
    iconeAlt: 'mdi-chevron-up',
    texto: 'Turmas',
    expandido: true,
    modulo: SISTEMA_PERMISSOES.TURMAS.MODULO,
    permissao: SISTEMA_PERMISSOES.TURMAS.PERMISSOES.ACESSO,
    filhos: [
      {
        icone: 'mdi-account-multiple-plus-outline',
        texto: 'Turmas',
        link: '/Turma',
        corBotao: 'white',
        corIcone: 'blue darken-3',
        modulo: SISTEMA_PERMISSOES.TURMAS.MODULO,
        permissao: SISTEMA_PERMISSOES.TURMAS.PERMISSOES.ACESSO
      },
      {
        icone: 'mdi-account-multiple-plus-outline',
        texto: 'Matrículas',
        link: '/Matricula',
        corBotao: 'white',
        corIcone: 'blue darken-3',
        modulo: SISTEMA_PERMISSOES.MATRICULAS.MODULO,
        permissao: SISTEMA_PERMISSOES.MATRICULAS.PERMISSOES.MANUTENCAO
      }
    ]
  },

  {
    icone: 'mdi-chevron-down',
    iconeAlt: 'mdi-chevron-up',
    texto: 'Sistema',
    expandido: true,
    modulo: SISTEMA_PERMISSOES.USUARIOS.MODULO,
    permissao: SISTEMA_PERMISSOES.USUARIOS.PERMISSOES.MANUTENCAO,
    filhos: [
      {
        icone: 'mdi-account-multiple-plus-outline',
        texto: 'Grupos',
        link: '/Grupo',
        corBotao: 'white',
        corIcone: 'blue darken-3',
        modulo: SISTEMA_PERMISSOES.GRUPOS.MODULO,
        permissao: SISTEMA_PERMISSOES.GRUPOS.PERMISSOES.MANUTENCAO
      },
      {
        icone: 'mdi-account-circle',
        texto: 'Usuários',
        link: '/Usuario',
        corBotao: 'white',
        corIcone: 'blue darken-3',
        modulo: SISTEMA_PERMISSOES.USUARIOS.MODULO,
        permissao: SISTEMA_PERMISSOES.USUARIOS.PERMISSOES.MANUTENCAO
      }
    ]
  },
  {
    icone: 'mdi-account-circle',
    texto: 'Meus Cursos',
    link: '/MeusCursos',
    corBotao: 'white',
    corIcone: 'blue darken-3',
    modulo: SISTEMA_PERMISSOES.USUARIOS.MODULO,
    permissao: SISTEMA_PERMISSOES.USUARIOS.PERMISSOES.MANUTENCAO
    // modulo: SISTEMA_PERMISSOES.ALUNOS.MODULO,
    // permissao: SISTEMA_PERMISSOES.ALUNOS.PERMISSOES.ACESSO
  },

  {
    icone: 'mdi-lock-reset',
    texto: 'Alterar Senha',
    link: '/AlterarSenha',
    corBotao: 'white',
    corIcone: 'blue darken-3'
  },

  {
    icone: 'mdi-account',
    texto: 'Selfie',
    link: '/Selfie',
    corBotao: 'white',
    corIcone: 'blue darken-3'
  },
  {
    icone: 'mdi-logout',
    texto: 'Sair',
    link: '/Logout',
    corBotao: 'white',
    corIcone: 'blue darken-3'
  }

]